import { Icons } from "icons";
import { Button } from "components";
import { TText } from "components";
import { QueryKeys } from "enums";
import { useStyle, useTranslation } from "hooks";
import { ModalContext } from "providers/ModalProvider";
import { useContext, useRef, useState } from "react";
import { Dimensions, Image, Platform, TextInput, View, useWindowDimensions } from "react-native";
import { useQuery } from "react-query";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import { DocumentUtils, QueryUtils } from "utils";
import BaseModal from "./BaseModal";


export default ({ settings }) => {

    const { width } = useWindowDimensions();
    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const { open, hideModal } = useContext(ModalContext);
    const translate = useTranslation();
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);

    // const translate = useTranslation();
    const [body, setBody] = useState('');
    const [response, setResponse] = useState(null);
    const [inputError, setInputError] = useState('');

    const [buttonTop, setButtonTop] = useState(false);
    const [loading, setLoading] = useState(false);
    const sending = useRef(false);

    const sendMessage = async () => {

        setInputError('');
        if (body.trim() == '' || sending.current) {
            let err = '#input_fields_required \n';
            if (!body) err += '• ' + '#description';
            if (!sending.current) setInputError(err);
            return;
        }

        setLoading(true);
        sending.current = true;

        const response = await DocumentUtils.uploadMessage(body, settings.screen);

        setResponse(response?.data?.success);
        setLoading(false);
        sending.current = false;

    }

    const reset = () => setResponse(null);





    const renderForm = () => {
        return (
            <View style={scopedStyles.container}>

                {response !== null ? (
                    <View style={{ width: '100%', flex: 1, justifyContent: "center" }}>
                        <TText lg regular primary center error={!response}>{response ? '#message_sent' : '#message_error'}</TText>
                        <View style={{ marginTop: 20, flexDirection: "row", justifyContent: 'center' }}>
                            <Button
                                label={response ? '#action_close' : '#action_retry'}
                                type={"secondary"}
                                onPress={response ? hideModal : reset}
                            />
                        </View>
                    </View>
                ) : (
                    <>

                        <View style={{ flex: 1 }}>
                            <TText lg regular primary style={scopedStyles.titel}>#edit_line</TText>

                            {buttonTop &&
                                <View style={{ flexDirection: "row", marginBottom: 20 }} >
                                    <Button
                                        loading={loading}
                                        label={'#action_hide_keyboard'}
                                        type={"secondary"}
                                        onPress={sendMessage}
                                    />
                                </View>
                            }

                            <TextInput
                                multiline={true}
                                placeholder={translate('#type_message_placeholder')}
                                value={body}
                                onChangeText={ntext => setBody(ntext)}
                                onBlur={() => { setButtonTop(false) }}
                                onFocus={() => { if (width < 997) setButtonTop(true) }}
                                autoCorrect={false}
                                style={[
                                    styles.textRegular,
                                    styles.textSM,
                                    scopedStyles.noteText
                                ]}
                            />
                        </View>

                        <View style={{ minHeight: 100, flexDirection: 'row', alignItems: 'center' }}>
                            <View style={{ flexDirection: "row" }}>
                                <Button
                                    loading={loading}
                                    label='#action_edit_long'
                                    type={"secondary"}
                                    onPress={sendMessage}
                                />
                            </View>
                            {inputError != '' &&
                                <View style={{ marginLeft: 12, flex: 1 }}>
                                    <TText sm error>{inputError}</TText>
                                </View>
                            }
                        </View>

                    </>
                )
                }


            </View >
        )
    }




    const renderAdvisor = () => {
        return (

            <View style={[scopedStyles.advisorContainer, { backgroundColor: colors.primary2 }]}>

                {customer?.advisor?.avatar ? (
                    <Image
                        source={{ uri: `data:image/png;base64,${customer?.advisor?.avatar}` }}
                        width={width > 768 ? 120 : 80}
                        height={width > 768 ? 120 : 80}
                        style={{
                            width: width > 768 ? 120 : 80,
                            height: width > 768 ? 120 : 80,
                            borderRadius: width > 768 ? 60 : 40
                        }}
                    />
                ) : (
                    <></>
                )}
                
                {/* <Icons.Avatar_male size={width > 768 ? 120 : 80} color={''} />*/}

                <View style={scopedStyles.advisorContact}>
                    <TText white sm semibold center>{customer?.advisor?.full_name}</TText>
                    <TText greyTransparent xs regular center>{customer?.advisor?.email}</TText>
                    <TText greyTransparent xs regular center>{customer?.advisor?.phone}</TText>
                </View>


            </View>
        )
    }





    return (
        <BaseModal style={scopedStyles.modal}>
            <View
                style={[[
                    {
                        flex: 1,
                        flexDirection: width < 768 ? 'column-reverse' : 'row',
                        justifyContent: "space-between"
                    },
                    Platform.OS === 'web' && { cursor: 'default' }
                ]]}
            >
                {renderForm()}
                {renderAdvisor()}
            </View>
        </BaseModal>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            minWidth: 485,
            width: '50%',
            height: '70%',
            minHeight: 485,
            marginTop: 150
        },
        container: {
            flex: 1,
            height: '100%',
            paddingTop: 20,
            paddingHorizontal: 30
        },
        advisorContainer: {
            height: '100%',
            width: 200,
            borderBottomRightRadius: 8,
            alignItems: "center",
            justifyContent: 'center'
        },
        titel: {
            marginVertical: 30
        },
        noteText: {
            backgroundColor: colors.lightgrey,
            flexGrow: 1,
            marginBottom: 30,
            padding: 8,
            borderRadius: 8,
            verticalAlign: "top",
            borderColor: colors.grey,
            borderWidth: 1
        },
        advisorContact: {
            justifyContent: "center",
            marginTop: 15
        }
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal: {
                minWidth: 'auto',
                width: '90%',
                marginLeft: 0,
                marginVertical: '20%',
                minHeight: Dimensions.get('window').height * 0.80,
            },
            advisorContainer: {
                width: '100%',
                height: 120,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: 8,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            },
            titel: {
                marginBottom: 20,
                marginTop: 0
            },
            advisorContact: {
                marginLeft: 12
            }
        }
    }
)