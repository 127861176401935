import { createDrawerNavigator, DrawerHeaderProps } from '@react-navigation/drawer';
import { DrawerActions } from '@react-navigation/routers';
import { useEffect } from 'react';
import { useWindowDimensions } from 'react-native';
import MaximumMortgage from 'screens/AdviesWidgets/MaximumMortgage';
import Avatar from 'screens/avatar';
import {
    Appointments,
    Contact,
    Documents,
    Inbox,
    Income,
    Landing,
    Dashboard,
    Living,
    Mortgages,
    Obligations,
    Policies,
    Possessions
} from 'screens/home';
import Damages from 'screens/home/Damages';
import Tasks from 'screens/home/Tasks';
import InventarisatiePortaal from 'screens/inventarisatie';
import { Drawer, Header } from 'screens/navigation';
import { colors } from 'styles';



export const DrawerNavigator = createDrawerNavigator();



export default ({ navigation }: { navigation?: any }) => {

    useEffect(() => {
        if (navigation) {
            //automatically close drawer when switching bottom tabs on mobile
            const listener = navigation.addListener('focus', () => {
                navigation.dispatch(DrawerActions.closeDrawer());
            });
            return listener;

        }
    }, [navigation]);
    const windowSize = useWindowDimensions();
    const largeScreen = windowSize.width >= 768;
    
    return (
        <DrawerNavigator.Navigator
            defaultStatus={largeScreen ? 'open' : 'closed'}
            drawerContent={
                (props) => <Drawer {...props} />
            }
            screenOptions={{
                header: (props) => <Header {...props} />,
                headerShown: true,
                headerStyle: { height: 120 },
                drawerType: largeScreen ? 'permanent' : 'slide',
                drawerPosition: 'left',
                drawerStyle: [largeScreen ? {width: 320 } : { width: '100%'}, {borderRightWidth: 0}],
                swipeEnabled: true,
                overlayColor: 'rgba(0,0,0,0.2)',
                sceneContainerStyle: { backgroundColor: colors.white }
            }}
            
            >

            <DrawerNavigator.Screen 
                name='dashboard'                
                component={Dashboard}
                options={{freezeOnBlur: true}}
                initialParams={{
                    title: '#menu_overview'
                }}
            />
            <DrawerNavigator.Screen name='overzicht'
                component={Landing}
                options={{freezeOnBlur: true}}
                initialParams={{ title: '#menu_overview' }}
            />
            <DrawerNavigator.Screen name='wonen'
                component={Living}
                options={{freezeOnBlur: true, lazy: false}} // preload gmaps
                initialParams={{ title: '#menu_living' }}
            />
            <DrawerNavigator.Screen name='hypotheken'
                component={Mortgages}
                options={{freezeOnBlur: true}}
                initialParams={{ title: '#menu_mortgage' }}
            />
            <DrawerNavigator.Screen name='verzekeringen'
                component={Policies}
                options={{freezeOnBlur: true}}
                initialParams={{ title: '#menu_policy' }}
            />
            <DrawerNavigator.Screen name='inkomen'
                component={Income}
                options={{freezeOnBlur: true}}
                initialParams={{ title: '#menu_income' }}
            />
            <DrawerNavigator.Screen name='pensioen'
                component={Income}
                options={{freezeOnBlur: true}}
                initialParams={{ title: '#menu_retirement' }}
            />
            <DrawerNavigator.Screen name='verplichtingen'
                component={Obligations}
                options={{freezeOnBlur: true}}
                initialParams={{ title: '#menu_obligation' }}
            />
            <DrawerNavigator.Screen name='contact'
                component={Contact}
                options={{freezeOnBlur: true, lazy: false}} // preload gmaps
                initialParams={{ title: '#menu_contact' }}
            />
            <DrawerNavigator.Screen name='services'
                component={Contact}
                options={{freezeOnBlur: true}}
                initialParams={{ title: '#menu_other_services' }}
            />


            <DrawerNavigator.Screen name='documenten'
                component={Documents}
                options={{freezeOnBlur: true}}
            />            
            <DrawerNavigator.Screen name='inbox'
                component={Inbox}
                options={{freezeOnBlur: true}}
            />
            <DrawerNavigator.Screen name='tasks'
                component={Tasks}
                options={{freezeOnBlur: true}}
            />
            <DrawerNavigator.Screen name='damages'
                component={Damages}
                options={{freezeOnBlur: true}}
            />
            <DrawerNavigator.Screen name='appointments'
                component={Appointments}      
                options={{freezeOnBlur: true}}          
            />
            

            <DrawerNavigator.Screen name='avatar'
                component={Avatar}
                options={{freezeOnBlur: true}}
            />
            <DrawerNavigator.Screen name='portaal'
                component={InventarisatiePortaal}
                options={{unmountOnBlur: true, freezeOnBlur: true}}
            />
            <DrawerNavigator.Screen name='AdviesWidgets'
                component={MaximumMortgage}
                options={{unmountOnBlur: true, freezeOnBlur: true}}
            />

        </DrawerNavigator.Navigator>
    );
}
