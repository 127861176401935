import { useNavigation } from "@react-navigation/native";
import { Icons } from "icons";
import { BottomNavBar, Button, ContentScroller, Icon, MortgageProgress, MortgageProgressCircle, ProgressBar, TText } from "components";
import { MortgageModal, MortgageRequestModal } from "components/Modals";
import { PortalInvitationType } from "enums";
import { useLayoutMargin, useSorted, useStyle } from "hooks";
import { Mortgage } from "models";
import { ModalContext } from "providers/ModalProvider";
import React, { useContext, useState } from "react";
import { Dimensions, Platform, Pressable, View, useWindowDimensions } from "react-native";
import { PieChart, ProgressCircle } from "react-native-svg-charts";
import { useQuery } from "react-query";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { Content } from 'screens/home';
import { colors } from "styles";
import { CalculationUtils, QueryUtils, ValutaUtils } from "utils";

import { QueryKeys } from 'enums';
import PageContainer from "components/PageContainer";
import { Card, HeaderCard, ScenariosCard } from "components/Card";

export default () => {
    const navigation = useNavigation();
    const { width, height } = useWindowDimensions();
    const styles = useStyle();
    const { showModal } = useContext(ModalContext);
    const margin = useLayoutMargin();

    const scopedStyles = scopedResponsiveStyles();
    const { data: mortgages } = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage);
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const { data: sortedMortgages, handleSearchQuery } = useSorted(mortgages);

    const { total, restant, payed } = CalculationUtils.totalMortgage(mortgages);
    const { monthly, aflossing, rente } = CalculationUtils.monthlyMortgage(mortgages);
    const [pieSize, setPieSize] = useState(null);

    const renderMortgageProgessCircle = () => {

        const amountAfgelost = (total != 0) ? (100 - Math.floor(restant / total * 100)) : 0;

        return (

            <View
                style={[scopedStyles.progressSizer, { width: pieSize }]}
                onLayout={(e) => setPieSize(e.nativeEvent.layout.height)}
            >
                <View style={scopedStyles.progressContainer}>
                    <ProgressCircle
                        style={scopedStyles.progressCircle}
                        strokeWidth={16}
                        progress={restant / total}
                        progressColor={colors.secondary}
                        backgroundColor={colors.primary}
                        startAngle={-Math.PI * 0.95}
                        endAngle={Math.PI * 0.95}
                    />
                    <View style={[styles.flexRow, { justifyContent: 'space-between' }]}>
                        <TText semibold xl primary style={{ transform: 'scale(1.5)' }}>{amountAfgelost}</TText>
                        <TText md primary style={{ marginTop: 15, marginLeft: 15 }}> %</TText>
                    </View>
                    <TText semibold sm primary center>#afgelost</TText>
                </View >
            </View>
        )
    }

    const renderButtons = () => {
        return (
            <Card style={[{flex: 0.31, backgroundColor: colors.primary, gap: 10}]}>
                <Button
                    label="#mortgage_hypotheekcheck"
                    type='secondary'
                    onPress={() => showModal(MortgageRequestModal, { mode: 'hypotheekcheck' }, 'MortgageRequestModal')}
                />
                <Button
                    label="#mortgage_verbouwen"
                    type='secondary'
                    onPress={() => showModal(MortgageRequestModal, { mode: 'verbouwen' }, 'MortgageRequestModal')}
                />
                <Button
                    label="#mortgage_verhuizen"
                    type='secondary'
                    onPress={() => showModal(MortgageRequestModal, { mode: 'verhuizen' }, 'MortgageRequestModal')}
                />
                {Platform.OS === 'web' ?                     
                    <Button
                        label={mortgages?.length == 0 ? "#mortgage_create" : "#mortgage_enrich"}
                        type='secondary'
                        onPress={() => navigation.navigate('portaal', { type: PortalInvitationType.MORTGAGE_ENRICH })}                        
                    />
                    : null}
            </Card>
        )
    }

    const renderMortgageTotal = () => {
        return (
            <Card style={[{flex: 0.69}, styles.flexRow]}>

                <View style={{flex: 0.5, height: '100%', alignItems: "center"}}>
                    <MortgageProgressCircle/>
                </View>

                <View style={{flex: 0.5, height: '100%', alignItems: "center"}}>

                    <Card style={{backgroundColor: colors.background, width: '100%'}}>

                        <TText md primary semibold style={{ marginBottom: 8 }}>#total_small_mortgage</TText>

                        <View style={[styles.flexRow, scopedStyles.dataRows]}>
                            <TText sm regular primary>#original_value</TText>
                            <TText sm semibold primary >{ValutaUtils.valutaString(total)}</TText>
                        </View>
                        <View style={[styles.flexRow, scopedStyles.dataRows]}>
                            <TText sm regular primary>#rest_value</TText>
                            <TText sm semibold primary >{ValutaUtils.valutaString(restant)}</TText>
                        </View>
                        <View style={[styles.flexRow, scopedStyles.dataRows]}>
                            <TText sm regular primary>#paid_value</TText>
                            <TText sm semibold primary >{ValutaUtils.valutaString(payed)}</TText>
                        </View>
                    
                        <View style={{
                            height: 0,
                            width: '100%',
                            marginVertical: 8,
                            borderColor: colors.border,
                            borderBottomWidth: 1
                        }}></View>


                        <View style={[styles.flexRow, scopedStyles.dataRows]}>
                            <TText sm regular primary>#monthly</TText>
                            <TText sm semibold primary>{ValutaUtils.valutaString(monthly)}</TText>
                        </View>
                        <View style={[styles.flexRow, scopedStyles.dataRows]}>
                            <TText sm regular primary style={{ alignSelf: 'center', flexGrow: 1 }}>#redeemed</TText>
                            <TText sm semibold primary style={{ alignSelf: 'center' }} >{ValutaUtils.valutaString(aflossing)}</TText>
                        </View>
                        <View style={[styles.flexRow, scopedStyles.dataRows]}>
                            <TText sm regular primary style={{ alignSelf: 'center', flexGrow: 1 }}>#interest</TText>
                            <TText sm semibold primary style={{ alignSelf: 'center' }} >{ValutaUtils.valutaString(rente)}</TText>
                        </View>
                    </Card>



                </View>
            </Card>
        );
    }


    const renderPie = (data, label = 0, drawZero = false) => {

        const pieData = data.map((val, index) => ({
            value: val.value,
            svg: {
                fill: val.color,
            },
            key: `pie-${index}`,
        }))

        return (drawZero && (!label || label == 0)) ? (
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <View style={{
                    width: width < 992 ? 100 : 80,
                    height: width < 992 ? 100 : 80,
                    borderRadius: 200,
                    borderColor: colors.primary,
                    borderWidth: 8,
                    justifyContent: "center",
                    alignItems: 'center'
                }}>
                    <TText primary sm center >{ValutaUtils.valutaString('0,0')}</TText>
                </View>
            </View>
        ) : (
            <View style={styles.fullscreen}>
                <PieChart style={{ width: '100%', height: '100%' }} data={pieData} innerRadius={'85%'} padAngle={0} />
                <View style={{ position: 'absolute', height: '100%', width: '100%', justifyContent: 'center' }}>
                    {label !== 0 && <TText primary sm regular center >{ValutaUtils.valutaString(label)}</TText>}
                </View>
            </View>
        );

    }

    const renderCurrentAddressBadge = (currentAdress: boolean) => {
        return (currentAdress) ? (
            <Button
                label="#primary_living"
                type='action'
                onPress={() => { }}
                unclickable
                style={{
                    position: 'absolute',
                    left: -10,
                    top: -10
                }}
            />
        ) : undefined;
    }


    const renderMortgageItems = () => {

        const activeAddress = customer?.addresses?.filter(address => address.active)[0] || { street_name: '', house_number: '', house_number_addition: '', city: '', postcode: '' };

        return mortgages?.length == 0 ? (
            <View style={[{ height: '100%', width: '100%', justifyContent: "center", alignItems: "center", flexDirection: 'row', marginLeft: margin * -0.5 }]}>
                <TText white md semibold>#empty_screen_pre #empty_screen_mortgages #empty_screen_post</TText>
            </View>
        ) : (
            sortedMortgages?.sort((a: Mortgage, b: Mortgage) => {
                const currentAdress = a.mainProperty.street && (
                    a.mainProperty.street == activeAddress.street_name &&
                    a.mainProperty.number == activeAddress.house_number &&
                    a.mainProperty.number_addition == activeAddress.house_number_addition &&
                    a.mainProperty.postal_code == activeAddress.postcode
                );
                return currentAdress ? -1 : 1;
            })
                ?.map((mortgage: Mortgage, index) => {

                    const total = mortgage.hoofdsomHypotheek;
                    const restant = mortgage.restantHoofdsom;
                    const payed = mortgage.hoofdsomHypotheek - mortgage.restantHoofdsom;
                    const monthly = mortgage.monthly_amount;
                    const aflossing = mortgage.monthly_repayment_amount;
                    const rente = mortgage.monthly_interest_amount;
                    const currentAdress = mortgage.mainProperty.street && (
                        mortgage.mainProperty.street == activeAddress.street_name &&
                        mortgage.mainProperty.number == activeAddress.house_number &&
                        mortgage.mainProperty.number_addition == activeAddress.house_number_addition &&
                        mortgage.mainProperty.postal_code == activeAddress.postcode
                    );

                    return (
                        <Pressable
                            key={mortgage.id + 'm' + index}
                            onPress={() => showModal(MortgageModal, { mortgage }, 'MortgageModal')}
                        >
                            <Card style={scopedStyles.mortgageItem}>

                                {/* current address icon */}
                                {renderCurrentAddressBadge(currentAdress)}

                                <View style={scopedStyles.addressContainer}>

                                    {/* house icon */}
                                    <Icon iconName="IconHome" iconSize={50} iconColor={colors.primary} />

                                    {/* address */}
                                    <View style={scopedStyles.homeAddress}>
                                        <TText md primary semibold center={width < 768}>{mortgage.mainProperty.street ? `${mortgage.mainProperty.street} ${mortgage.mainProperty.number}${mortgage.mainProperty.number_addition}` : ''}</TText>
                                        <TText sm primary regular center={width < 768}>{mortgage.mainProperty.street ? `${mortgage.mainProperty.postal_code}, ${mortgage.mainProperty.city}` : ' '}</TText>
                                    </View>

                                    {/* Agency */}
                                    <View style={scopedStyles.agencyName}>
                                        <TText md primary semibold center>{mortgage.agency_name}</TText>
                                        <View style={{ width: '100%', paddingHorizontal: 20, alignItems: "center" }}>
                                            <TText sm primary semibold center={width < 768}>{mortgage.hypotheeknummer}</TText>
                                        </View>

                                        {/* mortgage progress */}
                                        {(mortgage.status !== 0) &&
                                            <MortgageProgress mortgage={mortgage} />
                                        }
                                    </View>


                                </View>

                                <View style={scopedStyles.totalsContainer}>

                                    {/* total overview */}
                                    <View style={scopedStyles.mortgageTotalOverview}>

                                        <TText primary sm>#total_mortgage</TText>

                                        <View style={{
                                            backgroundColor: colors.background,
                                            flex: 1,
                                            marginTop: 4,
                                            justifyContent: 'space-evenly',
                                            borderRadius: 8,
                                            padding: 10
                                        }}>
                                            <View style={scopedStyles.mortgageValueContainer}>
                                                <ProgressBar
                                                    percentage={restant / total}
                                                    style={scopedStyles.progressBar}
                                                    barStyle={{ backgroundColor: colors.secondary9, borderRadius: 4 }}
                                                />
                                            </View>
                                            <View style={scopedStyles.mortgageValueContainer}>
                                                <TText primary sm>#original_value</TText>
                                                <TText primary sm >{ValutaUtils.valutaString(total, true)}</TText>
                                            </View>
                                            <View style={scopedStyles.mortgageValueContainer}>
                                                <TText primary sm>#rest_value</TText>
                                                <TText primary sm >{ValutaUtils.valutaString(restant, true)}</TText>
                                            </View>
                                            <View style={scopedStyles.mortgageValueContainer}>
                                                <TText primary sm>#paid_value</TText>
                                                <TText primary sm >{ValutaUtils.valutaString(payed, true)}</TText>
                                            </View>
                                        </View>
                                    </View>

                                    {/* monthly overview */}
                                    <View style={scopedStyles.mortgageMonthlyOverview}>

                                        <TText primary sm>#monthly_value</TText>

                                        <View style={{
                                            backgroundColor: colors.background,
                                            flex: 1,
                                            marginTop: 4,
                                            justifyContent: 'space-evenly',
                                            borderRadius: 8,
                                            padding: 10,
                                            flexDirection: "row"
                                        }}>

                                            <View style={{ flex: 0.3, justifyContent: "center" }}>

                                                <View style={{ minHeight: 110, height: 110 }}>
                                                    {renderPie(
                                                        [
                                                            { value: aflossing, color: colors.secondary9 },
                                                            { value: rente, color: colors.primary },
                                                        ],
                                                        monthly,
                                                        true
                                                    )}
                                                </View>

                                            </View>

                                            <View style={{ flex: 0.75, height: '100%', justifyContent: "space-between" }}>

                                                <View style={{ height: '50%', flexDirection: 'row', alignItems: "center" }}>

                                                    <View style={{ width: 30, height: 30, marginRight: 20, justifyContent: "center" }}>
                                                        {renderPie(
                                                            [
                                                                { value: aflossing, color: colors.secondary9 },
                                                                { value: rente, color: colors.darkprimary },
                                                            ]
                                                        )}
                                                    </View>
                                                    <TText sm regular primary style={{ alignSelf: 'center', flexGrow: 1 }}>#redeemed</TText>
                                                    <TText primary sm style={{ alignSelf: 'center' }} >{ValutaUtils.valutaString(aflossing)}</TText>

                                                </View>

                                                <View style={{ height: '50%', flexDirection: 'row', alignItems: "center" }}>
                                                    <View style={{ width: 30, height: 30, marginRight: 20, justifyContent: "center" }}>
                                                        {renderPie(
                                                            [
                                                                { value: aflossing, color: colors.darkprimary },
                                                                { value: rente, color: colors.primary },
                                                            ]
                                                        )}
                                                    </View>
                                                    <TText sm regular primary style={{ alignSelf: 'center', flexGrow: 1 }}>#interest</TText>
                                                    <TText primary sm style={{ alignSelf: 'center' }} >{ValutaUtils.valutaString(rente)}</TText>
                                                </View>
                                            </View>

                                        </View>
                                    </View>

                                </View>

                            </Card>
                        </Pressable>
                    )
                })
        );
    }

    return (
        <PageContainer>

            <View style={[styles.flexRowDynamic, styles.gap30]}>
                
                {renderMortgageTotal()}

                {renderButtons()}

            </View>

            <ScenariosCard/>

            <HeaderCard header="#title_mortgage">

                {renderMortgageItems()}

            </HeaderCard>

        </PageContainer>
    )
}


const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        progressContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
        },
        progressCircle: {
            position: 'absolute',
            height: '100%',
            width: '100%'
        },
        progressSizer: {
            height: '100%',
            right: 0,
            justifyContent: "center"
        },
        buttonContainer: {
            position: "absolute",
            bottom: 0,
            left: 0,
            width: '50%',
            flexDirection: 'row',
        },
        dataRows: {
            flexWrap: 'nowrap',
            width: '100%',
            justifyContent: 'space-between'
        },
        dataContainer: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            bottom: 0,
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: "flex-end"
        },        
        mortgageItem: {
            width: '100%',
            height: 200,
            borderRadius: 8,
            paddingVertical: 20,
            paddingHorizontal: 30,
            backgroundColor: colors.background,
            flexDirection: 'row',
            alignItems: "center"
        },
        progressBar: {
            width: '100%',
            height: 10,
            borderRadius: 4,
            backgroundColor: colors.primary,
            overflow: 'hidden'
        },
        mortgageValueContainer: {
            height: '25%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        addressContainer: {
            flexDirection: 'row',
            height: '100%',
            flex: 0.4,
            alignItems: "center"
        },
        totalsContainer: {
            flexDirection: 'row',
            height: '100%',
            flex: 0.6
        },
        homeAddress: {
            marginLeft: 20,
            flex: 0.3
        },
        agencyName: {
            marginHorizontal: 20,
            marginVertical: 8,
            flex: 0.7,
            justifyContent: "space-between"
        },
        mortgageTotalOverview: {
            height: '100%',
            flex: 0.5,
            justifyContent: 'space-between'
        },
        mortgageMonthlyOverview: {
            marginLeft: 20,
            height: '100%',
            flex: 0.5,
            justifyContent: 'space-between'
        }
    }, {

    [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
        mortgageDataContainer: {
            width: '40%',
            height: '65%',
            padding: 8,
            minWidth: 0,
            alignSelf: 'flex-start',
            marginTop: 32
        }
    },
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
        mortgageItem: {
            flexDirection: 'column',
            width: '100%',
            height: 'auto',
            minHeight: 580,
            maxHeight: 'auto',
            paddingHorizontal: 15,
            marginBottom: 40
        },
        mortgageTotalOverview: {
            minHeight: 150,
            width: '100%',
            justifyContent: 'space-between'
        },
        mortgageMonthlyOverview: {
            marginTop: 4,
            marginLeft: 0,
            minHeight: 150,
            width: '100%',
            justifyContent: 'space-between'
        },
        addressContainer: {
            width: '100%',
            flexDirection: 'column',
            flex: 0.45
        },
        totalsContainer: {
            width: '100%',
            flexDirection: 'column',
            flex: 0.55
        },
        agencyName: {
            width: '100%',
            flex: 0.1,
        }
    },
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
        progressContainer: {
            width: Dimensions.get('window').height * 0.32,
            height: Dimensions.get('window').height * 0.32,
            left: 0,
            top: 40
        },
        progressSizer: {
            height: 'auto',
            alignItems: 'center'
        },
        progressCircle: {
            height: '85%',
            width: '85%'
        },
        dataContainer: {
            width: '100%',
            flexDirection: 'column-reverse',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        buttonContainer: {
            position: 'relative',
            marginTop: 12,
            width: '100%',
            flexWrap: 'wrap'
        },
        mortgageDataContainer: {
            width: '100%',
            height: 'auto',
            justifyContent: 'flex-end'
        },
        mortgageItem: {
            flexDirection: 'column',
            width: '100%',
            height: 'auto',
            maxHeight: 'auto',
            paddingHorizontal: 15,
            marginBottom: 40
        },
        mortgageTotalOverview: {
            minHeight: 150,
            width: '100%',
            justifyContent: 'space-between'
        },
        mortgageMonthlyOverview: {
            marginLeft: 0,
            minHeight: 150,
            height: 150,
            width: '100%',
            justifyContent: 'space-between'
        },
        addressContainer: {
            width: '100%',
            flexDirection: 'column',
            flex: 0.45
        },
        totalsContainer: {
            width: '100%',
            flexDirection: 'column',
            flex: 0.55
        },
        homeAddress: {
            marginLeft: 0
        },
        agencyName: {
            marginHorizontal: 0
        }
    }
}
)
