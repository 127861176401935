import { useHover } from 'hooks';
import { useState } from 'react';
import { Pressable, View } from 'react-native';
import { colors } from 'styles';
import Icon from './Icon';

export default ({onChange, initialValue}: {onChange: (checked: boolean) => void, initialValue?: boolean}) => {

    const [ selected, setSelected ] = useState(initialValue);
    const [hovered, hoverElt] = useHover();
    const handleChange = () => {
        onChange(!selected);
        setSelected(!selected);
    }

    return (
        <Pressable ref={hoverElt} onPress={handleChange} >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View style={[
                    hovered && { backgroundColor: colors.primary2 },
                    { 
                        width: 20, 
                        height: 20, 
                        borderWidth: 1, 
                        borderColor: colors.primary, 
                        borderRadius: 5, 
                        justifyContent: 'center', 
                        alignItems: 'center' 
                    },
                ]}>
                    {selected && 
                        <Icon iconName='IconCheck' iconSize={14} iconColor={colors.primary}/>
                    }
                </View>
            </View>
        </Pressable>
    )
}