import { Icon } from "components";
import { View, ViewStyle } from "react-native"
import { colors } from "styles"

interface IconcardProps{
    iconName: string, 
    iconSize?: number, 
    iconColor?: string, 
    style?: ViewStyle, 
    rounded?: Boolean 
}

export default ({ iconName, iconSize, iconColor, rounded, style = {} }: IconcardProps) => {

    const color = iconColor || colors.black;
    const size = iconSize || 20;

    return (
        <View style={{
            width: 32,
            height: 32,
            borderRadius: rounded ? 32 : 7,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.secondary,
            ...style
        }}>
            <Icon iconName={iconName} iconColor={color} iconSize={size}/>
        </View>
    )
}
