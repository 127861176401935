import { useStyle } from "hooks";
import React, { ReactElement, useState } from "react";
import { Pressable, View } from "react-native";
import TText from "./TText";
import { colors } from "styles";

export default ({children, tabs}: {children?: any[], tabs: string[]}) => {
    
    const styles = useStyle();
    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <View style={[styles.fullscreen, styles.gap10]}>
            <View style={[styles.flexRow, styles.pv10]}>
                {tabs.map((tab, tabIndex) => {
                    const isTabSelected = selectedTab === tabIndex;
                    return (
                        <Pressable
                            disabled={isTabSelected}
                            onPress={() => setSelectedTab(tabIndex)}
                        >
                            <TText
                                semibold={isTabSelected}
                                style={[
                                    styles.p10,
                                    styles.borderRadius5,
                                    {
                                        backgroundColor: isTabSelected ? colors.secondary2 : colors.transparent,
                                        color: isTabSelected ? colors.secondary : colors.primary,
                                    }
                                ]}
                            >
                                {tab}
                            </TText>
                        </Pressable>
                    );
                })}
            </View>
            {children && children[selectedTab]}
        </View>
    );
}