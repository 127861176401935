import { useNavigation } from "@react-navigation/native";
import { Icons } from "icons";
import { TText, Button, CloseButton } from "components"
import { useLayoutMargin, useStyle } from "hooks";
import { useState } from "react";
import { StyleSheet, useWindowDimensions, View } from "react-native";
import styles, { colors } from "styles";
import Card from "./Card";


export default () => {

    const navigation = useNavigation();
    const styles = useStyle();

    return (
        <Card style={[{backgroundColor: colors.primary, flex: 0.4}]}>

                <View style={[styles.flexStretch, styles.gap10]}>
                    <TText white md semibold>#brondata_collect</TText>
                    <TText white sm regular>#brondata_description</TText>
                </View>
                <View style={[styles.flexRow, styles.alignEnd, styles.justifyEnd]}>
                    <Button
                        label='#brondata_action'
                        type="primary"
                        onPress={() => navigation.navigate('portaal')}
                        style={{backgroundColor: colors.blackLessTransparent}}
                    />
                </View>
            
        </Card>
    )
}