import { PlatformWebview } from "components";
import { useStyle } from "hooks";
import { View } from "react-native";

import adviesWidgetHTML from 'assets/html/advies_widgets';

interface widgetProps{
    state: string
    widgetName: string,
    widgetUrl: string
}

export default ({state, widgetUrl, widgetName}: widgetProps) => {

    const styles = useStyle();

    return (
        <View style={styles.fullscreen}>
            {(state && widgetName && widgetUrl) && 
                <PlatformWebview
                    html={adviesWidgetHTML}
                    style={styles.fullscreen}
                    replaceVars={[
                        ['WIDGET_STATE', state],
                        ['WIDGET_NAME', widgetName],
                        ['WIDGET_URL', widgetUrl],
                    ]}
                />            
            }
        </View>
    )

}
