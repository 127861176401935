import { useRoute } from '@react-navigation/native';
import { Icons } from 'icons';
import { CustomerDrawerToggle, TText } from 'components';
import { QueryKeys } from 'enums';
import { useHover, useStyle } from 'hooks';
import { useEffect, useState } from 'react';
import { Dimensions, Image, Platform, Pressable, View, useWindowDimensions } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { initialWindowMetrics } from 'react-native-safe-area-context';
import { useQuery } from 'react-query';
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import colors from 'styles/colors';
import { QueryUtils, SentryUtils } from 'utils';

const header_height = 120;

const Header: React.FC<{ navigation: any, route: any }> = ({ navigation, route }) => {

    
    const scopedStyles = scopedResponsiveStyle();
    const styles = useStyle();

    const state = navigation.getState();
    const currentRouteTitle = state.routes.find(navRoute => navRoute.name === route.name).params?.title;
    
    return (
        <>
            <View style={scopedStyles.header}>

                <TText lg semibold primary>{currentRouteTitle || route.name}</TText>

                <View style={[styles.flexStretch, styles.flexRow, styles.alignCenter, styles.justifyEnd]}>

                    <CustomerDrawerToggle/>
                </View>
               
            </View>
        </>

    );
};

const scopedResponsiveStyle = CreateResponsiveStyle(
    {
        header: {
            height: header_height,
            width: '100%',
            backgroundColor: colors.background,
            zIndex: 999,
            borderTopLeftRadius: 35,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: "space-between",
            paddingHorizontal: 40
        },        
    }
)

export default Header;
