import Constants from 'expo-constants';
import ApiUtils from "./ApiUtils";
import { Customer } from 'models';

const Config = Constants?.expoConfig?.extra?.config;

export default {
    async view(model: string, id: string | number) {
        const client = await ApiUtils.getApiClient({});
        return client.post('/customer/customer/view', { model, id });
    },
    async getUsernameByToken(token: string) {
        const client = await ApiUtils.getApiClient({});
        return client.post('/customer/auth/token/validate', { token, include: 'customer' });
    },
    getFullName(customer: Customer){
        return `${customer?.first_name ? customer.first_name : ''}${customer?.suffix ? ' ' + customer.suffix : ''} ${customer?.last_name ? customer.last_name : ''}`
    }
}