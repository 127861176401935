import { StyleSheet, ViewStyle, View } from "react-native"
import { useStyle } from "hooks";
import { colors } from "styles";
import TText from "components/TText";

interface props {
    children?: React.ReactNode
    style?: ViewStyle | ViewStyle[],
    header: string
}

export default ({ children, style = [], header }: props) => {

    const styles = useStyle();
    
    return (
        <View style={[{backgroundColor: colors.white}, styles.borderRadius20, styles.p30, style]}>
            
            <View style={{paddingBottom: 30}}>
                <TText md semibold primary>{header}</TText>
            </View>

            {children}
        </View>
    )
}