import { Icons } from "icons";
import { BaseItem, BottomNavBar, Button, ContentScroller, Icon, IncomePie, TText } from "components";
import { EditModal, IncomeModal } from "components/Modals";
import { IncomeCategories, IncomeNames, IncomeTypes, QueryKeys, Screen } from 'enums';
import { useLayoutMargin, useSorted, useStyle } from "hooks";
import { Obligation } from "models";
import { ModalContext } from "providers/ModalProvider";
import { useContext, useEffect, useState } from "react";
import { Dimensions, Image, Platform, Pressable, View, useWindowDimensions } from "react-native";
import { ProgressCircle } from "react-native-svg-charts";
import { useQuery } from "react-query";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { Content } from 'screens/home';
import { colors } from "styles";
import { CalculationUtils, QueryUtils, ValutaUtils } from "utils";

const incomeIcons = {
    benefit: 'IconMoneybag',
    company: 'IconHomeStats',
    employment: 'IconBriefcase2',
    retirement: 'IconOldAge',
    other: 'IconDots',
}

export default () => {

    const { width } = useWindowDimensions();
    const largeScreen = width > 768;
    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const { showModal } = useContext(ModalContext)

    const { data: incomes } = useQuery(QueryKeys.INCOME, QueryUtils.fetchIncome);
    const { data: retirements } = useQuery(QueryKeys.RETIREMENT, QueryUtils.fetchRetirement);
    const { data: sortedIncomes, handleSearchQuery, setData } = useSorted();

    const [pieSize, setPieSize] = useState(200);
    const { totalAccumulatedRetirement, totalRetirementTarget } = CalculationUtils.calculateRetirement(retirements);
    const margin = useLayoutMargin();

    const navItems = [
        { title: '#income_now', key: IncomeCategories.income },
        { title: '#income_later', key: IncomeCategories.retirement },
    ];
    const [category, setCategory] = useState(IncomeCategories.income);
    const [categoryCount, setCategoryCount] = useState(0);

    useEffect(() => {

        if (!incomes || !retirements) return;

        //collect all incomes per type in one array so it can be sorted with the searchbox        
        const unsorted = [];
        switch (category) {
            case IncomeCategories.income:

                for (let key in incomes) {
                    if (key.includes('incomes')) {
                        const type = key.split('_')[0];
                        incomes[key]?.map(income => {
                            const inc = { ...income, income_type: type };
                            unsorted.push(inc);
                        })
                    }
                }
                break;

            case IncomeCategories.retirement:

                for (let key in retirements) {

                    if (key.includes('_retirements')) {
                        retirements[key]?.map(retirement => {
                            const ret = { ...retirement, income_type: 'retirement' };
                            unsorted.push(ret);
                        })
                    } else if (key == 'retirement_benefit_income' && retirements[key]) {
                        const ret = { ...retirements[key], income_type: 'retirement' };
                        unsorted.push(ret);
                    }
                }
                break;
        }

        setData(unsorted);
        setCategoryCount(unsorted.length);

    }, [category, incomes, retirements])


    const renderRetirementProgessCircle = () => {
        const amountOpgebouwd = Math.floor((totalAccumulatedRetirement * 100) / totalRetirementTarget) || 0;

        return (

            <View
                style={[scopedStyles.progressSizer, , { width: pieSize }]}
                onLayout={(e) => {
                    setPieSize(e.nativeEvent.layout.height);
                }}
            >
                <View style={scopedStyles.progressContainer}>
                    <ProgressCircle
                        style={scopedStyles.progressCircle}
                        strokeWidth={16}
                        progress={totalAccumulatedRetirement / totalRetirementTarget}
                        progressColor={colors.secondary}
                        backgroundColor={colors.grey}
                        startAngle={-Math.PI * 0.95}
                        endAngle={Math.PI * 0.95}
                    />
                    <View style={[styles.flexRow, { justifyContent: 'space-between' }]}>
                        <TText semibold xl black style={{ transform: 'scale(1.5)' }}>{amountOpgebouwd}</TText>
                        <TText md black style={{ marginTop: 15, marginLeft: 15 }}> %</TText>
                    </View>
                    <TText semibold sm grey center>#build-up</TText>
                </View >
            </View>
        )
    }

    const renderRetirementData = () => {
        if (categoryCount == 0) return;

        return (
            <View style={scopedStyles.retirement}>

                <View style={[
                    scopedStyles.retirementDataContainer,
                ]}>

                    <TText sm black semibold style={{ marginBottom: 8 }}>#total_retirement_title</TText>

                    <View style={[styles.flexRow, scopedStyles.retirementData]}>
                        <TText sm regular grey>#total_retirement_goal</TText>
                        <TText sm regular black >{ValutaUtils.valutaString(totalRetirementTarget)}</TText>
                    </View>
                    <View style={[styles.flexRow, scopedStyles.retirementData]}>
                        <TText sm regular grey>#total_retirement_accumulated</TText>
                        <TText sm regular black >{ValutaUtils.valutaString(totalAccumulatedRetirement)}</TText>
                    </View>

                </View>

                {renderRetirementProgessCircle()}

            </View>
        );
    }

    const renderIncomePie = () => {

        if (categoryCount == 0) return;

        return (
            <>
                {(largeScreen) &&
                    <View style={{
                        width: 150,
                        top: '1%',
                        alignSelf: 'flex-end',
                        marginRight: 300
                    }}>
                        <TText regular sm black>#hover</TText>
                        <Image
                            style={{
                                position: 'absolute',
                                width: 50,
                                height: 50,
                                marginTop: '15%',
                                marginLeft: '80%'
                            }}
                            source={require('assets/images/arrow.png')}
                        />
                    </View>
                }

                <View
                    style={[scopedStyles.pieChart, { width: pieSize }]}
                    onLayout={(e) => setPieSize(e.nativeEvent.layout.height)}
                >
                    <IncomePie
                        dark
                        opklik
                        style={{ width: '100%', height: '100%' }}
                    />
                </View>
            </>
        )
    }


    const renderIncomeItems = () => {

        if (categoryCount == 0) {
            return (
                <View style={[{ height: '100%', flex: 1, justifyContent: "center", alignItems: "center", flexDirection: 'row' }]}>
                    <TText white md semibold>#empty_screen_pre_unknown{category == IncomeCategories.income ? '#empty_screen_incomes' : '#empty_screen_retirements'}#empty_screen_post_unknown</TText>
                </View>
            )
        }

        //incomes per type in object
        if (category == IncomeCategories.income) {

            const incomePerType: Record<string, Obligation[]> = {};

            sortedIncomes?.map((income: any, index: number) => {

                if (!incomePerType[income.income_type]) incomePerType[income.income_type] = [];


                switch (income.income_type) {
                    case IncomeTypes.benefit:
                        incomePerType[income.income_type].push({
                            id: income.description,
                            title: income.benefit_type_description,
                            total: income.gross_annual_allowance + income.holiday_bonus
                        });
                        break;

                    case IncomeTypes.company:
                        incomePerType[income.income_type].push({
                            id: income.description,
                            title: `${income.name} ${income.legal_type_description}`,
                            total: income.independent_income
                        });
                        break;

                    case IncomeTypes.employment:
                        const incomeTotal = income.gross_annual_income +
                            income.holiday_bonus +
                            income.fixed_13th_month +
                            income.fixed_end_year_benefit +
                            income.irregularity_bonus +
                            income.overtime_per_year +
                            income.provision_per_year;
                        incomePerType[income.income_type].push({
                            id: income.description,
                            title: income.employer_name,
                            total: incomeTotal
                        });
                        break;

                    case IncomeTypes.other:
                        incomePerType[income.income_type].push({
                            id: income.description,
                            title: income.type_description,
                            total: income.gross_annual_income
                        });
                        break;

                }
            })

            return Object.entries(incomePerType).map(entry => {

                const [key, data]: [string, any] = entry;
                const total = data.reduce((acc, val) => acc + val.total, 0);
                const settings = {
                    title: IncomeNames[key],
                    incomes: sortedIncomes.filter(item => item.income_type == key),
                    iconName: incomeIcons[key],
                    total: total
                };
                const showDetails = () => showModal(IncomeModal, settings, 'IncomeModal');

                return (
                    <Pressable
                        key={'inc' + key}
                        onPress={showDetails}
                        style={[Platform.OS == 'ios' && { marginBottom: margin }]}
                    >

                        <BaseItem
                            buttonValue={ValutaUtils.valutaString(total) + ' #p/j'}
                            buttonAction={showDetails}
                        >

                            <View style={{
                                flex: 0.7,
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: 20
                            }}>
                                <Icon
                                    iconName={settings.iconName}
                                    iconSize={80}
                                    iconColor={colors.primary}
                                />
                                <TText semibold md primary center style={{ marginTop: 8, marginBottom: 20 }}>{IncomeNames[key]}</TText>
                            </View>

                            <View style={[styles.highlight, { flex: 0.3 }]}>
                                {
                                    data?.slice(0, 2)?.map((val, index) => (
                                        <View key={'d' + index} style={[styles.line, { marginBottom: 4 }]}>
                                            <TText oneline sm grey style={{ flex: 1 }}>{val.title}</TText>
                                            <TText sm grey>{ValutaUtils.valutaString(val.total)}</TText>
                                        </View>
                                    ))
                                }
                            </View>
                        </BaseItem>

                    </Pressable>
                )
            })

        } else if (category == IncomeCategories.retirement) {

            return sortedIncomes?.map((income: any, index: number) => {

                const agency = income.agency || (income.description && income.description.split(' € ')[0]) || '';
                const total = income.target_pension_amount || income.gross_annual_allowance;
                const accumulated = income.accumulated_pension_amount || income.accumulated_amount
                const settings = {
                    title: IncomeNames.retirement,
                    incomes: [income],
                    Icon: incomeIcons.retirement,
                    total: total
                }
                const showDetails = () => showModal(IncomeModal, settings, 'IncomeModal')

                return (
                    <Pressable key={'i' + index} onPress={showDetails}>
                        <BaseItem
                            buttonValue={ValutaUtils.valutaString(total) + ' #p/j'}
                            buttonAction={showDetails}
                        >

                            <View style={{
                                flex: 0.7,
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: 20
                            }}>
                                <settings.Icon
                                    size={80}
                                    color={colors.primary}
                                />
                                <TText semibold md primary center style={{ marginTop: 8 }}>{IncomeNames[income.income_type]}</TText>
                                <TText semibold sm grey center style={{ marginBottom: 12 }}>{agency}</TText>
                            </View>

                            <View style={[styles.highlight, { flex: 0.3 }]}>

                                <View key={'d' + index} style={[styles.line, { marginBottom: 4 }]}>
                                    <TText oneline sm grey style={{ flex: 1 }}>#built_up_retirement</TText>
                                    <TText sm grey>{ValutaUtils.valutaString(accumulated)}</TText>
                                </View>

                                <View key={'d' + index + '2'} style={[styles.line, { marginBottom: 4 }]}>
                                    <TText oneline sm grey style={{ flex: 1 }}>#retirement_goal</TText>
                                    <TText sm grey>{ValutaUtils.valutaString(income.target_pension_amount || income.gross_annual_allowance)}</TText>
                                </View>

                            </View>
                        </BaseItem>
                    </Pressable>
                )
            })

        }

    }





    return (
        <Content
            title="#title_income"
        >
            <View style={styles.fullscreen}>
                <TText sm regular black style={scopedStyles.underTitle}>#undertitle_income</TText>

                <View style={[{ flexDirection: 'row', position: "absolute", bottom: 0, zIndex: 99 }]}>
                    <Button
                        label="#action_edit_long"
                        type='action'
                        onPress={() => showModal(EditModal, { screen: Screen.INKOMEN }, 'EditModal')}
                    />
                </View>

                {category == IncomeCategories.income && renderIncomePie()}
                {category == IncomeCategories.retirement && renderRetirementData()}
            </View>

            <BottomNavBar
                noSearch={(incomes?.length && incomes?.length == 0) && (incomes?.length && retirements?.length == 0)}
                navItems={navItems}
                handleNavItemSelect={(e) => setCategory(e.key)}
                handleSearchQuery={handleSearchQuery}
            />

            <View style={{ width: '100%', flex: 1 }}>

                <ContentScroller>
                    {renderIncomeItems()}
                </ContentScroller>

            </View>

        </Content>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        underTitle: {},
        incomeInfo: {
            backgroundColor: colors.white,
            width: '47%',
            flex: 1,
            marginTop: 20,
            padding: 20,
            borderRadius: 8,
            justifyContent: "space-around"
        },
        pieChart: {
            position: 'absolute',
            height: '110%',
            right: -20,
            marginTop: '-2%'
        },
        retirement: {
            width: '100%',
            flex: 1,
            marginTop: -40,
            flexDirection: 'row',
            justifyContent: "flex-end",
            alignItems: "center"
        },
        retirementDataContainer: {
            width: 400,
            height: '50%',
            padding: 12,
            backgroundColor: colors.white,
            borderRadius: 8,
            justifyContent: 'space-around'
        },
        retirementData: {
            justifyContent: "space-between"
        },
        progressContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%'
        },
        progressSizer: {
            height: '100%',
            right: 0,
            justifyContent: "center"
        },
        progressCircle: {
            position: 'absolute',
            height: '100%',
            width: '100%'
        },
    }, {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
        underTitle: {
            flex: 0.1
        },
        incomeInfo: {
            width: '100%',
            flex: 0.36
        },
        totalInfoContainer: {
            width: '100%',
            flex: 0.5
        },
        incomeItem: {
            width: Dimensions.get('window').width * 0.8,
            height: 'auto',
            minHeight: Dimensions.get('window').height * 0.35,
            marginVertical: 20,
            marginLeft: 0
        },
        pieChart: {
            position: 'absolute',
            width: Dimensions.get('window').height * 0.35,
            height: Dimensions.get('window').height * 0.35,
            left: (Dimensions.get('window').width * 0.5) - (Dimensions.get('window').height * 0.2),
            top: Dimensions.get('window').height * 0.05
        },
        progressContainer: {
            width: Dimensions.get('window').height * 0.32,
            height: Dimensions.get('window').height * 0.32,
            left: 0
        },
        progressSizer: {
            alignItems: 'center',
        },
        retirement: {
            flexDirection: 'column-reverse',
            justifyContent: "flex-start",
            flex: 1
        },
        retirementDataContainer: {
            height: 'auto',
            width: '100%',
            marginTop: -80,
            marginBottom: 80
        }
    }
}
)