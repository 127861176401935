import { useQuery } from "react-query";
import { QueryKeys } from "enums";
import { AdviesWidgetUtils, QueryUtils } from "utils";

import { useEffect, useState } from "react";
import BaseWidget from "./BaseWidget";
import PageContainer from "components/PageContainer";


export default () => {

    
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const [state, setState] = useState('')
    const widgetName = "MaximumMortgageWidget";
    const widgetUrl = "https://blackbirds-cdn.cluster02.k8s.rapide.software/max-mortgage/latest/bundle.js";

    useEffect(() => {
        if (customer){
            setState(AdviesWidgetUtils.getMaximumMortgageState(customer));
        }
    }, [customer])
   
    return (
        <PageContainer>
            <BaseWidget
                state={state}
                widgetName={widgetName}
                widgetUrl={widgetUrl}
            />
        </PageContainer>
    )

}
