import { LocalStorageKeys, QueryKeys } from "enums";
import * as Updates from 'expo-updates';
import moment from "moment";
import { createContext, useEffect, useRef, useState } from "react";
import { Platform, StyleSheet } from "react-native";
import { useQuery, useQueryClient } from "react-query";
import { ApiUtils, AuthUtils, QueryUtils, StorageUtils } from "utils";
import ResponsiveStyles from "styles";

moment.updateLocale('nl', {
    months: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"]
});
moment.updateLocale('en', {
    months: ["Januari", "Februari", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
});
moment.updateLocale('de', {
    months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
});
moment.locale('nl');

type StylesType = ReturnType<typeof ResponsiveStyles>;

export const StoreContext = createContext<{
    language: string,
    notifications: boolean,
    loggedIn: boolean,
    error500: boolean,
    tenantError: boolean,
    errorGeneric: boolean,
    appointments_made: number,
    error: string,
    styles: StylesType,
    login: () => void,
    logout: (e?: string) => void,
    setLanguage: (e: string) => void,
    setNotification: (e: boolean) => void,
    makeAppointment: (e: object) => void,
    loadingTrigger: boolean,
    triggerLoading: () => void,
    reloadApp: (e?: {}) => void,
    query: any
}>({
    language: 'nl',
    notifications: true,
    loggedIn: false,
    error500: false,
    errorGeneric: false,
    tenantError: false,
    appointments_made: 0,
    error: '',
    styles: {},
    login: () => { },
    logout: (e) => { },
    setLanguage: (e) => { },
    setNotification: (e) => { },
    makeAppointment: (e) => { },
    loadingTrigger: false,
    triggerLoading: () => { },
    reloadApp: (e) => { },
    query: {}
});

export default ({ children }) => {

    //@ts-ignore
    const getQuery = Platform.select({
        web: () => { return new URLSearchParams(window.location.search) },
        default: () => { return { has: (e: string) => false, get: (e: string) => '' } }
    });

    const [state, setState] = useState({
        loggedIn: false,
        error500: false,
        errorGeneric: false,
        tenantError: false,
        appointments_made: 0,
        error: '',
        language: 'nl',
        query: getQuery()
    });

    // export const styles: StylesType = CreateResponsiveStyles();
    const styles: StylesType = ResponsiveStyles();
    

    const queryCache = useQueryClient();
    const [loadingTrigger, setLoadingTrigger] = useState(false);
    const triggerLoading = () => setLoadingTrigger(t => !t);

    //load language, notifications
    useEffect(() => {
        const loadFromStorage = async () => {
            const lang = await StorageUtils.getStorageItem(LocalStorageKeys.LANGUAGE);
            const notifications = await StorageUtils.getStorageItem(LocalStorageKeys.NOTIFICATION);
            setState(s => ({
                ...s,
                language: lang ? lang : 'nl',
                notifications: (!notifications) ? false : JSON.parse(notifications)
            }))
        };
        loadFromStorage();
    }, [])

    //auth
    const login = () => {
        setState(s => ({
            ...s,
            loggedIn: true,
            error500: false,
            errorGeneric: false,
            tenantError: false,
            error: ''
        }));
    }
    const logout = async (error?: string) => {
        const currentUserId = await AuthUtils.getCurrentUserId();
        if (currentUserId) {
            const users = await AuthUtils.getUsers();
            if ( users[currentUserId] ) users[currentUserId].logout();
            await AuthUtils.storeUsers(users);
        }
        AuthUtils.removeCurrentUser();
        queryCache.clear();
        setState(s => ({
            ...s,
            loggedIn: false,
            error500: false,
            tenantError: false,
            error: error || ''
        }));
    }

    //language
    const setLanguage = async (lang) => {
        setState(s => ({
            ...s,
            language: lang,
        }))
        moment.locale(lang);
        const currentUserId = await AuthUtils.getCurrentUserId();
        if (currentUserId) {
            const users = await AuthUtils.getUsers();
            users[currentUserId] = {
                ...users[currentUserId],
                language: lang
            };
            AuthUtils.storeUsers(users);
        }
    }

    //notifications
    const setNotification = async (notification_state: any) => {
        setState(s => ({
            ...s,
            notifications: notification_state
        }))
        const currentUserId = await AuthUtils.getCurrentUserId();
        if (currentUserId) {
            const users = await AuthUtils.getUsers();
            users[currentUserId] = {
                ...users[currentUserId],
                notification: String(notification_state)
            };
            AuthUtils.storeUsers(users);
        }
    }

    //appointments
    const makeAppointment = ({ clear = false } = {}) => {
        setState(s => ({
            ...s,
            appointments_made: clear ? 0 : s.appointments_made + 1
        }))
    }


    //errors
    const setError = async (status) => {
        if (ApiUtils.isAuthenticationError(status)) {
            logout();
            if (Platform.OS === 'web') {
                window.location.reload();
            } else {
                Updates.reloadAsync();
            }
        } else {
            setState(s => ({
                ...s,
                errorGeneric: status || true
            }));
        }
    }
    //@ts-ignore
    global.setError = setError;

    //error500
    const setError500 = () => {
        setState(s => ({
            ...s,
            error500: true
        }))
    }
    //@ts-ignore
    global.setError500 = setError500; //has to go over global because there's no connection


    //error-invalid tenant
    const setTenantError = () => {
        setState(s => ({
            ...s,
            tenantError: true
        }))
    }
    //@ts-ignore
    global.setTenantError = setTenantError; //has to go over global because there's no connection

    const reloadApp = async (params?: Record<string, string>) => {
        const reload = Platform.select({
            web: () => {
                let url = window.location.origin;
                if (params) url = url + '/?' + new URLSearchParams(params).toString();
                window.location.href = url;
            },
            default: () => {
                queryCache.clear();
                setState(s => ({
                    ...s,
                    loggedIn: false,
                    error500: false,
                    error: ''
                }));
            }
        })
        await AuthUtils.removeCurrentUser();
        reload();
    }

    return (
        <StoreContext.Provider value={{
            ...state, 
            login, 
            logout, 
            setLanguage, 
            setNotification, 
            loadingTrigger, 
            triggerLoading, 
            makeAppointment, 
            reloadApp,
            styles
        }}>
            {children}
        </StoreContext.Provider>
    );
}

