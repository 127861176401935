import { useStyle, useTranslation } from "hooks"
import { Dimensions, View } from "react-native"
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles"
import { colors } from "styles"
import { Calendar, Button } from 'components';
import BaseModal from './BaseModal';
import moment from "moment";

export default ({ hideModal, handleDateSelect, initialDate, preventFutureDate = false, preventPastDate = false }) => {

    const scopedStyles = scopedResponsiveStyles();
    const translate = useTranslation();

    const weekdays = [
        translate('#monday_short'),
        translate('#tuesday_short'),
        translate('#wednesday_short'),
        translate('#thursday_short'),
        translate('#friday_short'),
        translate('#saturday_short'),
        translate('#sunday_short'),
    ];
    const months = moment.months();

    return (
        <BaseModal style={scopedStyles.modal} hideModal={hideModal}>

            <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                <View style={scopedStyles.datePickerContainer}>
                    <Calendar
                        style={scopedStyles.calendar}
                        calendarProps={{        
                            weekdays, 
                            months,
                            nextTitle: translate('#next') + ' >',
                            previousTitle: '< ' + translate('#previous'),
                            startFromMonday: true,
                            selectedDayColor: colors.primary,
                            selectedDayTextColor: colors.white,
                            textStyle: {
                                fontFamily: 'Poppins_400Regular'
                            },
                            headerWrapperStyle: scopedStyles.calendarHeader,
                            showDayStragglers: false,
                            selectedStartDate: initialDate,
                            initialDate: initialDate,
                            maxDate: preventFutureDate ? moment() : undefined,
                            minDate: preventPastDate ? moment() : undefined

                        }}
                        onDateChange={(date) => handleDateSelect(date)}
                    />
                </View>
                <View style={{ flexDirection: "row", justifyContent: "flex-end", marginBottom: 10, marginRight: 20 }}>
                    <Button
                        label="#action_date"
                        type="action"
                        style={scopedStyles.button}
                        onPress={hideModal}
                    />
                </View>
            </View>

        </BaseModal>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            flex: 1,
            width: '40%',
            minWidth: 250,
            marginLeft: 0,
            paddingVertical: 20,
            paddingLeft: 30,
            marginVertical: 150,
            backgroundColor: colors.background,
            borderRadius: 8,
            overflow: 'visible'
        },
        datePickerContainer: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: "flex-end",
            alignItems: "center"
        },
        calendar: {
            width: '80%',
            flex: 0.9
        },
        calendarHeader: {
            width: '95%'
        },
        button: {}
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal: {
                width: '90%',
                marginHorizontal: 12,
                marginVertical: 30,
                paddingLeft: 0,
                minHeight: Dimensions.get('window').height * 0.75,
            },
            calendar: {
                marginTop: '30%',
                width: '100%',
                flex: 1
            },
            calendarHeader: {
                width: '100%'
            },
            button: {
                width: '60%',
                marginLeft: '40%'
            },
        }
    }
)