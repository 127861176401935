import { colors } from "styles";

const map = `<!DOCTYPE html>
<html>
<head>
    <base href="https://klantportaal.net/api">
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <script src="WIDGET_URL"></script>
    <link href="https://blackbirds-cdn.cluster02.k8s.rapide.software/css/latest/bundle.css" rel="stylesheet"/>
    <style>
        @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;&display=swap');
        html,
        body {
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: ${colors.background};
        }
        * {
            font-family: "Poppins", sans-serif !important;
        }
        #advies-widgets-div {
            max-width: 800px;
            margin-top: 22px;
        }
        .btn-primary{
            background-color: ${colors.primary} !important;
        }
    </style>
</head>

<body>
    <div id="advies-widgets-div"></div>
    <script>
        const widgetState = WIDGET_STATE;
        // document.body.innerHTML = document.body.innerHTML + '<p>' + JSON.stringify(widgetState, null, 4) + '</p>';
        
        window.widget = new WIDGET_NAME({
            api_key: 'tsglDkWr9wgrz6JdSTihYZrhEm6JefhAqdOt5MAhVU1h1MNgEusMs5hOdhiieDes',
            url: '//blackbirds-widgets.cluster02.k8s.rapide.software/api'
        });
        widget?.init('#advies-widgets-div');

        const fillState = (key, value) => {
            let keys = key.split('.');
            let obj = window;

            for (let i = 0; i < keys.length - 1; i++) {
                obj = obj[keys[i]];
            }

            obj[ keys[ keys.length - 1 ] ] = value; 
        }

        const interval = setInterval(() => {
            if (!window.widget.loading){
                clearInterval(interval);
                // document.body.innerHTML = document.body.innerHTML + '<p> widget is loaded! Adding state</p>';
                Object.entries(widgetState).map(([key, value]) => fillState(key, value));
                // document.body.innerHTML = document.body.innerHTML + '<p>' + JSON.stringify(window.state, null, 4) + '</p>';
                window.widget?.reload();
            }
        }, 100);
        
    </script>
</body>

</html>`

export default map;