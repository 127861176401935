import { Icons } from "icons";
import { Button, Icon, TText } from "components";
import { Card, IconCard } from "components/Card";
import { useHover } from "hooks";
import { useState } from "react";
import { ActivityIndicator, Dimensions, Platform, Pressable, View } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";


interface moduleProps {
    title: string
    underTitle?: string
    text: any
    buttonValue?: any
    buttonAction?: any
    disabled?: boolean
    warning?: boolean
    deletable?: boolean
    onDelete?: () => void
}

export default ({ title, underTitle, text, buttonValue, buttonAction, disabled, warning, deletable, onDelete }: moduleProps) => {

    const scopedStyles = scopedResponsiveStyles();
    const [hovered, hoverElt] = useHover();
    const disableModule = disabled && !(Platform.OS == 'web' && hovered);
    const native = Platform.OS == 'android' || Platform.OS == 'ios' || Dimensions.get("window").width < 768;
    const [deleting, setDeleting] = useState(false);

    return (
        <Card 
            ref={hoverElt}
            style={[{backgroundColor: colors.primary2, flex: 1, minHeight: 270, maxHeight: 270}]}
        >

            {warning &&
                <View style={{ position: "absolute", top: 13, right: 8, zIndex: 1 }}>
                    <Icon iconName="IconAlertOctagon" iconColor={colors.error} iconSize={18} />
                </View>
            }

            <TText primary md semibold grey={(disableModule)} style={scopedStyles.title}>{title}</TText>
            {underTitle && <TText sm semibold grey style={scopedStyles.underTitle}>{underTitle}</TText>}

            <View style={[scopedStyles.text, disableModule && { opacity: 0.5 }]}>
                {text}
            </View>

            {buttonValue &&
                <View style={{ flexDirection: 'row', justifyContent: deletable ? "space-between" : "flex-end" }}>                        
                    <Button
                        label={buttonValue}
                        type={'primary'}
                        onPress={buttonAction}
                    />
                </View>
            }
        </Card>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        title: {
            flexDirection: 'row',
            justifyContent: "space-between",
            alignItems: 'center'
        },
        underTitle: {
            flexDirection: 'row',
            justifyContent: "space-between",
            alignItems: 'center',
            marginBottom: 12
        },
        text: {
            flex: 1,
            marginTop: 8
        }
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            decoration: {
                position: 'absolute',
                top: 0,
                left: 0,
                height: '200%',
                width: 5
            }
        }
    }
)