import { TText } from "components";
import { useStyle } from "hooks";
import { StoreContext } from "providers/StoreProvider";
import { useContext } from 'react';
import { Image, View } from "react-native";

const headerImage = require('assets/images/header.png');

export const ErrorGeneric = () => {

    const styles = useStyle();
    const { errorGeneric } = useContext(StoreContext);

    let title = 'Wegens success onbereikbaar.';
    let message = 'Onze servers zijn op dit moment overbelast. Een moment geduld.';

    if (errorGeneric === 503) message = "Op dit moment word er gepland onderhoud verricht. We zijn zo snel mogelijk weer terug!"

    return (
        <View style={[styles.fullscreen, styles.justifyCenter, styles.alignCenter]}>
            <Image source={headerImage} style={{ width: 500, height: 100, marginBottom: 22 }} resizeMethod='resize' />
            <TText primary xl semibold>{title}</TText>
            <View style={{ position: 'absolute', flexDirection: 'row', justifyContent: 'center', bottom: 40 }}>
                <TText primary md semibold>{message}</TText>
            </View>
        </View>
    )

}