import { ViewStyle, View, Platform, Pressable } from "react-native"
import { useHover, useSignalItems, useStyle } from "hooks";
import HeaderCard from "./HeaderCard";
import Card from "./Card";
import { colors } from "styles";
import TText from "components/TText";
import LivableWidget from "components/widgets/LivableWidget";
import Icon from "components/Icon";
import IconCard from "./IconCard";
import { useContext } from "react";
import { ModalContext } from "providers/ModalProvider";
import FlexGrid from "components/FlexGrid";

const Scenarios = [
    {
        title: 'Kan ik blijven wonen?',
        iconName: 'IconHome',
        component: LivableWidget
    },
    {
        title: 'Kan ik mijn huis verduurzamen?',
        iconName: 'IconSeeding',
        component: LivableWidget
    },
    {
        title: 'Hoeveel kan ik lenen?',
        iconName: 'IconPigMoney',
        component: LivableWidget
    },
    {
        title: 'Kan ik blijven wonen?',
        iconName: 'IconHome',
        component: LivableWidget
    },
    {
        title: 'Kan ik blijven wonen?',
        iconName: 'IconHome',
        component: LivableWidget
    },
];

export default ({style}: {style?: ViewStyle}) => {

    const styles = useStyle();
    const { showModal, hideModal } = useContext(ModalContext);

    const showScenario = (scenario) => {

        showModal(scenario.component, {hideModal}, scenario.title);

    }
    
    const renderScenarioButtons = () => {
        return Scenarios.slice(0, 3).map(scenario => {
            
            return (
                <Pressable
                    key={scenario.title}
                    style={styles.flexStretch}
                    onPress={() => showScenario(scenario)}
                >
                    <Card 
                        hoverable
                        style={{
                            backgroundColor: colors.white, 
                            color: colors.primary,                            
                            gap: 20,
                            flex: 1
                        }}
                    >
                        <IconCard
                            iconName={scenario.iconName}
                            iconSize={25}
                            iconColor={colors.white}
                            style={{
                                position: 'absolute', 
                                width: 40, 
                                height: 40, 
                                left: 20, 
                                top: -20
                            }}
                        />
                        <TText md semibold style={{color: colors.primary}}>{scenario.title}</TText>
                        <Icon
                            iconName="IconArrowRight"
                            iconSize={30}
                            iconColor={colors.secondary}
                            style={{
                                position:'absolute',
                                bottom: 10,
                                right: 20
                            }}
                        />
                    </Card>
                </Pressable>
            );
        });
    }

    return (
        <Card style={[
            {backgroundColor: colors.secondary1}, 
            styles.flexRowDynamic,
            style
        ]}>

            <View style={{flex: 0.31}}>
                <TText md semibold primary>Scenario's</TText>
                <TText sm primary style={{width: '75%', marginTop: 10}}>Bekijk snel hoeveel je kan lenen, of je kan blijven en zelfs of je je huis kan verduurzamen.</TText>
            </View>

            {/* <View style={[styles.flexRow, styles.alignCenter, styles.gap30, {flex: 0.57}]}> */}
            <FlexGrid columns={3} style={{flex: 0.57}}>
                {renderScenarioButtons()}
            </FlexGrid>

            <View style={[styles.justifyCenter, {flex: 0}]}>
                <Card 
                    style={{
                        backgroundColor: colors.secondary5, 
                        color: colors.secondary,
                        minWidth: 200,
                        maxWidth: 200,
                        minHeight: 170,
                        maxHeight: 170,
                        marginLeft: 30
                    }}
                >
                    <TText md semibold style={{color: colors.secondary}}>Bekijk meer scenario's</TText>
                    <Icon
                        iconName="IconArrowRight"
                        iconSize={30}
                        iconColor={colors.secondary}
                        style={{
                            position:'absolute',
                            bottom: 10,
                            right: 20
                        }}
                    />
                </Card>
            </View>

        </Card>
    )
}