import { TText } from 'components';
import { QueryKeys } from 'enums';
import { useEffect, useState } from 'react';
import { Dimensions, View } from 'react-native';
import { useQuery } from 'react-query';
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles';
import { CalculationUtils, QueryUtils, ValutaUtils } from 'utils';



//This screen show an overview of the customers values

export default ({ style }) => {

    const scopedStyles = scopedResponsiveStyles();
    const smallScreen = Dimensions.get('window').width < 768;

    const { data: mortgages } = useQuery(QueryKeys.MORTGAGE, QueryUtils.fetchMortgage);
    const { data: income } = useQuery(QueryKeys.INCOME, QueryUtils.fetchIncome);
    const { data: retirement } = useQuery(QueryKeys.RETIREMENT, QueryUtils.fetchRetirement);
    const { data: obligations } = useQuery(QueryKeys.OBLIGATION, QueryUtils.fetchObligation);

    const [data, setData] = useState({
        // possessions: 0,
        mortgages: 0,
        retirement: 0,
        dept: 0,
        obligations: 0,
        income: 0,
    });

    useEffect(() => {
        setData({
            mortgages: CalculationUtils.calculateMortgage(mortgages),
            retirement: CalculationUtils.calculateRetirement(retirement),
            dept: CalculationUtils.calculateDept(obligations),
            obligations: CalculationUtils.calculateObligations(obligations),
            income: CalculationUtils.totalIncome(income).total
        });
    }, [])

    const noData = () => {
        return Object.entries(data).reduce((acc, entry) => {
            if (entry[1] > 0) return false;
            return acc;
        }, true);
    }

    return (
        <View style={style}>

            <View style={{ flex: noData() ? 1 : 0.15 }}>
                <TText md semibold white>#title_number</TText>
            </View>

            {noData() ? (
                <View style={{ position: 'absolute', paddingHorizontal: 30, top: 0, left: 0, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <TText greyTransparent sm regular>#empty_numericOverview</TText>
                </View>
            ) : (
                <View style={scopedStyles.itemContainer}>
                    <View style={scopedStyles.itemRight}>
                        <TText greyTransparent sm semibold>#mortgage</TText>
                        <TText white regular lg xl={smallScreen} >{ValutaUtils.valutaString(data.mortgages, true)}</TText>
                    </View>
                    <View style={scopedStyles.item}>
                        <TText greyTransparent sm semibold>#income</TText>
                        <TText white regular lg xl={smallScreen} >{ValutaUtils.valutaString(data.income, true)}</TText>
                    </View>
                    <View style={scopedStyles.itemRight}>
                        <TText greyTransparent sm semibold>#pension</TText>
                        <TText white regular lg xl={smallScreen} >{ValutaUtils.valutaString(data.retirement, true)}</TText>
                    </View>
                    <View style={scopedStyles.item}>
                        <TText greyTransparent sm semibold>#debt</TText>
                        <TText white regular lg xl={smallScreen} >{ValutaUtils.valutaString(data.dept, true)}</TText>
                    </View>
                    <View style={scopedStyles.itemRight}>
                        <TText greyTransparent sm semibold>#obligation</TText>
                        <TText white regular lg xl={smallScreen} >{ValutaUtils.valutaString(data.obligations, true)}</TText>
                    </View>
                </View>
            )}

        </View>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        itemContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
            alignItems: 'center',
            flex: 1
        },
        item: {
            minWidth: '60%'
        },
        itemRight: {
            minWidth: '40%'
        }
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            itemContainer: {
                flexWrap: 'nowrap',
                flexDirection: 'column',
                justifyContent: 'space-around'
            },
            item: {
                width: '100%',
            },
            itemRight: {
                width: '100%',
                alignItems: 'flex-end'
            }
        }
    }
)