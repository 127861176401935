import { Modal, Pressable, ScrollView, useWindowDimensions, View, ViewStyle } from "react-native";
import * as Animatable from 'react-native-animatable';
import { colors } from "styles";
import { useStyle } from "hooks";
import React from "react";
import TText from "components/TText";
import CloseButton from "components/CloseButton";

interface props{
    header?: string,
    visible: boolean, 
    drawerWidth: number,
    drawerStyle?: ViewStyle, 
    children?: React.ReactNode
    onClose: () => void 
}

export default ({ header, visible, drawerWidth = 500, drawerStyle, children, onClose } : props) => {

    const styles = useStyle();
    const dimensions = useWindowDimensions();
    const width = Math.min(drawerWidth, dimensions.width);

    return (
        <Modal 
            transparent 
            visible={visible} 
            onRequestClose={onClose}
        >
            <Pressable
                style={[styles.fullscreen, {cursor: 'auto'}]} 
                onPress={onClose}
            >
                <Animatable.View
                    style={{                        
                        width: width,
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                    }}
                    animation={{
                        from: { right: -width },
                        to:   { right: 0 },
                    }}
                    delay={0}
                    duration={150}
                >
                    <Pressable 
                        style={[
                            styles.fullscreen,                            
                            styles.boxShadow, 
                            {
                                borderTopLeftRadius: 35,
                                borderBottomLeftRadius: 35,                             
                                shadowRadius: 16,
                                elevation: 12,
                                shadowOffset: { width: -10, height: 0 }, shadowOpacity: 0.1,
                                cursor: 'auto',
                            },
                        ]} 
                        onPress={() => {}}
                    >
                        <View style={[
                            styles.flexRow, 
                            styles.alignCenter, 
                            styles.justifyBetween, 
                            styles.ph30,
                            styles.pv20,
                            { borderTopLeftRadius: 35, backgroundColor: colors.white }
                        ]}>
                            <TText md semibold>{header}</TText>
                            <CloseButton onPress={onClose}/>
                        </View>

                        <ScrollView 
                            style={drawerStyle}
                            contentContainerStyle={[
                                styles.ph30, 
                                styles.pb30, 
                                {
                                    borderBottomLeftRadius: 35, 
                                    backgroundColor: colors.white,
                                    minHeight: '100%'
                                }                                    
                            ]}
                        >                        
                            {children}
                        </ScrollView>
                    </Pressable>
                </Animatable.View>
                
            </Pressable>
        </Modal>
    )
}
