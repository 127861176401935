import { Text, View, ViewStyle } from "react-native"
import TText from "./TText";
import { useStyle } from "hooks";
import { IconCard } from "./Card";
import { colors } from "styles";


export default ({style, iconOnly}: {style?: ViewStyle, iconOnly?: boolean}) => {

    const styles = useStyle();    

    return (
        <View style={[styles.pv30, styles.flexRow, styles.gap10, styles.alignCenter, styles.justifyCenter, style]}>
            <IconCard
                rounded
                iconName="IconHomeShield"
                iconColor={colors.white}
                iconSize={iconOnly ? 120 : 20}
                style={{
                    backgroundColor: colors.primary,
                    width: iconOnly ? 180 : 32,
                    height: iconOnly ? 180 : 32,
                    borderRadius: iconOnly ? 180 : 7
                }}
            />
            {!iconOnly && 
                <Text>
                    <TText semibold lg primary>Hypotheek</TText>
                    <TText regular lg grey>wacht</TText>
                </Text>
            }
        </View>
    )
}