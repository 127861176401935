import { Button, TText, SecureTextInput } from "components";
import { useFormik } from "formik";
import { useTranslation } from "hooks";
import { ModalContext } from "providers/ModalProvider";
import { useContext, useState } from "react";
import { Dimensions, Platform, View } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import { AuthUtils } from "utils";
import { object, ref, string } from "yup";
import BaseModal from "./BaseModal";

const changePasswordSchema = object({
    currentPassword: string().required('#login_error_password'),
    newPassword: string().required('#login_error_password').min(8, '#password_min_length'),
    newPasswordConfirmation: string().required('#login_error_password').min(8, '#password_min_length').when('newPassword', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: string().oneOf([ref('newPassword')], '#new_password_equal')
    })
});

export default ({ settings }) => {

    const scopedStyles = scopedResponsiveStyles();
    const translate = useTranslation();
    const { hideModal } = useContext(ModalContext);


    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const {
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        errors,
        touched
    } = useFormik({
        validationSchema: changePasswordSchema,
        initialValues: { currentPassword: '', newPassword: '', newPasswordConfirmation: '' },
        onSubmit: values => changePassword(values),
    });

    const changePassword = async ({ currentPassword, newPassword, newPasswordConfirmation }: { currentPassword: string, newPassword: string, newPasswordConfirmation: string }) => {
        if (loading) return;
        setLoading(true);
        const response = await AuthUtils.changePassword(currentPassword, newPassword, newPasswordConfirmation);
        setTimeout(() => {
            setResponse(response.data.success);
            setLoading(false);
        }, 2000)

    }

    const reset = () => setResponse(null);





    const renderForm = () => {
        return (
            <View style={scopedStyles.container}>

                {response !== null ? (
                    <View style={{ width: '100%', flex: 1, justifyContent: "center" }}>
                        <TText lg regular primary center error={!response}>{response ? '#password_changed' : '#password_changeError'}</TText>
                        <View style={{ marginTop: 20, flexDirection: "row", justifyContent: 'center' }}>
                            <Button
                                label={response ? '#action_close' : '#action_retry'}
                                type={"secondary"}
                                onPress={response ? hideModal : reset}
                            />
                        </View>
                    </View>
                ) : (
                    <>
                        <View style={{ flex: 1 }}>

                            <TText lg regular primary style={scopedStyles.titel}>#title_change_password</TText>

                            <View style={{ flex: 0.8, justifyContent: "space-around" }}>

                                <SecureTextInput
                                    tabIndex={1}
                                    label="#current_password"
                                    placeholder={translate("#current_password")}
                                    value={values.currentPassword}
                                    onChangeText={handleChange('currentPassword')}
                                    onBlur={handleBlur('currentPassword')}
                                    onKeyPress={(e) => (e.keyCode === 13) && handleSubmit()}
                                />
                                <TText regular sm error style={{ textAlign: 'right' }}>{(touched.currentPassword && errors.currentPassword) ? errors.currentPassword : ' '}</TText>

                                <SecureTextInput
                                    tabIndex={2}
                                    autoCompleteType="password"
                                    label="#new_password"
                                    placeholder={translate("#new_password")}
                                    value={values.newPassword}
                                    onChangeText={handleChange('newPassword')}
                                    onBlur={handleBlur('newPassword')}
                                    onKeyPress={(e) => (e.keyCode === 13) && handleSubmit()}
                                />
                                <TText regular sm error style={{ textAlign: 'right' }}>{(touched.newPassword && errors.newPassword) ? errors.newPassword : ' '}</TText>

                                <SecureTextInput
                                    tabIndex={3}
                                    label="#new_password_confirmation"
                                    placeholder={translate("#new_password_confirmation")}
                                    value={values.newPasswordConfirmation}
                                    onChangeText={handleChange('newPasswordConfirmation')}
                                    onBlur={handleBlur('newPasswordConfirmation')}
                                    onKeyPress={(e) => (e.keyCode === 13) && handleSubmit()}
                                />
                                <TText regular sm error style={{ textAlign: 'right' }}>{(touched.newPasswordConfirmation && errors.newPasswordConfirmation) ? errors.newPasswordConfirmation : ' '}</TText>

                            </View>

                        </View>

                        <View style={{ height: 100, justifyContent: 'center' }}>


                            <View style={{ flexDirection: "row" }} >
                                <Button
                                    loading={loading}
                                    label={`${'#change_password'} ➜`}
                                    type={"secondary"}
                                    onPress={handleSubmit}
                                />
                            </View>

                        </View>

                    </>
                )
                }


            </View >
        )
    }






    return (
        <BaseModal style={scopedStyles.modal}>
            <View
                style={[[
                    {
                        flex: 1,
                        flexDirection: Dimensions.get('window').width < 768 ? 'column-reverse' : 'row',
                        justifyContent: "space-between"
                    },
                    Platform.OS === 'web' && { cursor: 'default' }
                ]]}
            >
                {renderForm()}
            </View>
        </BaseModal>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            minWidth: 485,
            width: '40%',
            height: '50%',
            minHeight: 485,
            marginTop: 150
        },
        container: {
            flex: 1,
            height: '100%',
            paddingTop: 20,
            paddingHorizontal: 30
        },
        advisorContainer: {
            height: '100%',
            width: 200,
            borderBottomRightRadius: 8,
            alignItems: "center",
            justifyContent: 'center'
        },
        titel: {
            marginTop: 20,
            marginBottom: 50
        },
        noteText: {
            backgroundColor: colors.lightgrey,
            flexGrow: 1,
            marginBottom: 30,
            padding: 8,
            borderRadius: 8,
            verticalAlign: "top",
            borderColor: colors.grey,
            borderWidth: 1
        },
        advisorContact: {
            justifyContent: "center",
            marginTop: 15
        }
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal:{
                minWidth:0,
                width: '95%'

            },
            advisorContainer: {
                width: '100%',
                height: 120,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: 8,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            },
            titel: {
                marginBottom: 20,
                marginTop: 0
            },
            advisorContact: {
                marginLeft: 12
            }
        }
    }
)