export default {
    //greetings
    '#greeting_morning': 'Good morning',
    '#greeting_afternoon': 'Good afternoon',
    '#greeting_evening': 'Good evening',
    '#welcome': 'Welcome to the online dossier of your financial service provider.',

    //login
    '#login_advisor': 'Office',
    '#login_advisor_code': 'Office code',
    '#login_username': 'Username',
    '#login_password': 'Password',
    '#login_login': 'login',
    '#login_forgotpassword': 'forgot password',
    '#login_back': 'return to login',
    '#login_renewpassword': 'Request new password',
    '#login_recoverysent': 'An e-mail has been sent. Click on the link in the email to reset your password.',

    '#login_error_username': 'Username cannot be empty',
    '#login_error_password': 'Password cannot be empty',
    '#login_error_token': 'This link has expired',
    '#login_error_tenant': 'Tenant cannot be empty',
    '#login_error': 'An error occurred while logging in',
    '#login_error_notfound': 'The login details are unknown',
    '#login_error_noauth': 'Username or password is incorrect',
    '#login_error_retry': 'Something went wrong, please try again',

    '#login_2FA': 'Authentication code',
    '#login_authcode_sent': 'An email has been sent with an authentication code. You can enter the code below.',
    '#login_error_authcode': 'Authentication code cannot be empty',

    //brondata
    '#brondata_collect': 'Collect all your details',
    '#brondata_description': 'For your first meeting with your advisor, we need some information from you. You can easily collect it via our inventory portal. Depending on your chosen option, this will take approximately 5 to 20 minutes.',
    '#brondata_description_short': 'For your first meeting with your advisor, we need some information from you. You can easily collect it via our inventory portal.',
    '#brondata_action': 'start inventory',

    //numeric overview
    '#possession': 'Possession',
    '#mortgage': 'Mortgage',
    '#income': 'Income',
    '#retirement': 'Retirement',
    '#debt': 'Debt',
    '#obligation': 'Obligation',

    //mortgage overview
    '#redeemed': 'Redemption',
    '#interest': 'Interest',
    '#premium': 'Premium',

    //page titles
    '#title_number': 'My Numbers',
    '#title_mortgage': 'My mortgages',
    '#title_policy': 'My insurances',
    '#title_income': 'My income',
    '#title_obligations': 'My obligations',
    '#title_inbox': 'My messages',
    '#title_tasks': 'My tasks',
    '#title_damages': 'My damages',
    '#title_appointments': 'My appointments',
    '#title_documents': 'My documents',
    '#title_notes': 'My notes',
    '#title_upload': 'Upload Document',
    '#title_upload_multiple': 'Upload multiple documents',
    '#title_message': 'Message',
    '#title_userdetails': 'My details',
    '#title_contact': 'Contact',
    '#title_settings': 'My settings',
    '#title_avatar': 'Edit profile picture',
    '#title_send_message': 'Send a message',
    '#title_reply_message': 'Reply to a message',
    '#title_damage': 'Report your damage here',

    '#title_tasks_and_messages': 'Taks and messages',

    //undertitles
    '#undertitle_documents': 'Here you can find all that you have ever sent. Do you have a document we should look at? You can upload it here.',
    '#undertitle_mortgage': 'Here you can find all information related to your mortgages.',
    '#undertitle_policies': 'Here you can find all information related to your policies.',
    '#undertitle_income': 'Here you can find all information related to your income.',
    '#undertitle_obligation': 'Here you can find all information related to your obligations.',
    '#undertitle_inbox': 'Here you can find all communication that has taken place between you and the advisor.',
    '#undertitle_tasks': 'Here you can find all the tasks that your advisor has set up for you.',
    '#undertitle_damages': 'Here you can find all damage reports that have been reported to your advisor.',
    '#undertitle_appointments': 'Here you can find all appointments that has been made between you and the advisor',
    '#undertitle_create_appointment': 'Here you can make an appointment with your advisor. Select a date and reason for the appointment to get the available bookable times.',

    //misc.
    '#hello': 'Hello',
    '#on': 'Turned on',
    '#off': 'Turned off',
    '#search': 'search..',
    '#more': 'More',
    '#total': 'TOTAL',
    '#total_small': 'Total',
    '#total_small_mortgage': 'Total mortgages',
    '#afgelost': 'paid off',
    '#openstaand': 'outstanding',
    '#total_mortgage': 'Total mortgage',
    '#hover': 'Hover for more info!',
    '#description': 'Description',
    '#add_note': 'Add note',
    '#remove': 'remove',
    '#language': 'Language',
    '#notifications': 'Notifications',
    '#clear_cache': 'clear cache',
    '#all_messages': 'all messages',
    '#type_message_placeholder': 'Write your message here..',
    '#service_line': 'What can we be of service to you?',
    '#edit_line': 'What changes do you want to make?',

    //documents
    '#upload': 'upload document',
    '#download': 'download',
    '#open': 'open',
    '#sort': 'sort',
    '#klanten': 'personal',
    '#polissen': 'policies',
    '#hypotheken': 'mortgages',
    '#krediet': 'income',
    '#verplichtingen': 'obligations',
    '#bankzaken': 'banking',

    //inbox
    '#message_single': 'Message',
    '#message_action_read': 'read',
    '#message_read': 'Message read',
    '#message_new': 'New message',



    //openinghours
    '#opening_hours': 'Opening hours',
    '#closed': 'Closed',
    '#monday': 'Monday',
    '#tuesday': 'Tuesday',
    '#wednesday': 'Wednesday',
    '#thursday': 'Thursday',
    '#friday': 'Friday',
    '#saturday': 'Saturday',
    '#sunday': 'Sunday',
    '#hour': 'hour',

    '#monday_short': 'Mon',
    '#tuesday_short': 'Tue',
    '#wednesday_short': 'Wed',
    '#thursday_short': 'Thu',
    '#friday_short': 'Fri',
    '#saturday_short': 'Sat',
    '#sunday_short': 'Sun',

    //uploads
    '#uploads_other': 'Other uploads',
    '#uploads_different_document': 'select different document',
    '#uploads_cancel': 'cancel',
    '#uploads_confirm': 'confirm',
    '#uploads_description': 'Manually upload a file to your advisor.',
    '#uploads_retry': 'retry uploading',
    '#uploads_error': 'Something went wrong. Please try again',

    //values and periods
    '#original_value': 'Original amount',
    '#rest_value': 'Outstanding amount',
    '#paid_value': 'Amount repaid',
    '#monthly_value': 'Monthly amount',
    '#per_month': 'per month',
    '#per_year': 'per year',
    '#monthly': 'Monthly',
    '#monthly_interest': 'Monthly interest',
    '#monthly_redeemed': 'Monlthy redeemed',
    '#monthly_premium': 'Monthly premium',
    '#p/j': 'p/y',
    '#p/m': 'p/m',

    //actions
    '#action_view': 'view',
    '#action_edit': 'edit',
    '#action_edit_long': 'Submit changes  ➜',
    '#action_damage': 'report damage',
    '#action_send_message': 'send message',
    '#action_view_message': 'view message',
    '#action_view_complete_message': 'view complete message',
    '#action_upload': 'upload',
    '#action_add': 'add',
    '#action_logout': 'Log out',
    '#action_close': 'close',
    '#action_retry': 'retry',
    '#action_react': 'reply',
    '#action_date': 'select date',
    '#action_appointment_type': 'select appointment reason',
    '#action_appointment_type_resource': 'select advisor',
    '#action_retrieve': 'retrieve',
    '#next': 'Next',
    '#previous': 'Previous',
    '#optional': 'optional',

    //menu items
    '#menu_overview': 'Dashboard',
    '#menu_mortgage': 'My mortgages',
    '#menu_policy': 'My insurances',
    '#menu_living': 'My home',
    '#menu_income': 'My income',
    '#menu_retirement': 'My retirement',
    '#menu_obligation': 'My obligations',
    '#menu_other_services': 'Other services',
    '#menu_contact': 'Contact',

    //genders and age
    '#gender_male': 'Male',
    '#gender_female': 'Female',
    '#unknown': 'Unknown',
    '#age': 'years',
    '#birth_on': 'Born on',
    '#birth_at': 'at',

    //family
    '#partner': 'Partner',
    '#children': 'Children',

    //uploading
    '#upload_empty': 'We do not need any documents from you at this time.',
    '#upload_pre': 'To complete your mortgage application, we need document',
    '#upload_post': 'from you.',
    '#upload_pre_multi': 'To complete our inventory, we still need',
    '#upload_post_multi': 'documents from you.',

    //empty overview strings
    '#empty_numericOverview': 'Here you can see your most important figures at a glance. From assets to liabilities, everything in one handy overview.',
    '#empty_mortgageOverview': 'In this block you can quickly see what is outstanding in mortgages and what you repay per month.',
    '#empty_policyOverview': 'In this block you can immediately see how much you spend on insurance per month and what it consists of.',

    //empty pages string
    '#empty_screen_pre': 'You have not yet taken out',
    '#empty_screen_post': 'with us.',
    '#empty_screen_pre_unknown': 'No',
    '#empty_screen_post_unknown': 'is known to us yet.',

    '#empty_screen_mortgages': 'mortgage',
    '#empty_screen_policies': 'insurance',
    '#empty_screen_incomes': 'income',
    '#empty_screen_retirements': 'retirement',
    '#empty_screen_obligations': 'obligation',

    '#empty_screen_inbox': "You haven't received any messages from us yet.",
    '#empty_screen_tasks': "You haven't received any tasks from us yet.",
    '#empty_screen_damages': "You haven't reported any damage to us yet.",
    '#empty_screen_appointments': "You haven't scheduled any appointments with us yet.",

    //income
    '#income_employment': 'Employment',
    '#income_company': 'Company',
    '#income_benefit': 'Benefit',
    '#income_retirement': 'Retirement',
    '#income_other': 'Other income',

    '#income_type': 'Income type',
    '#start_date': 'Start date',
    '#end_date': 'End date',
    '#company_name': 'Company name',
    '#company_employes': 'Does the company have employes?',
    '#company_start_date': 'Company start date',
    '#employment_name': 'Employer name',
    '#employment_city': 'Location',
    '#employment_start_service': 'Start employment',
    '#employment_end_service': 'End employment',
    '#employment_function': 'Position',
    '#employment_fulltime': 'Fulltime contract',
    '#employment_hours_per_week': 'Hours per week',

    '#income_gross_yearly': 'Gross benefit per year',
    '#income_holiday_bonus': 'Holiday allowance',
    '#income_net_profit': 'Net profit',
    '#income_net_profit_total': 'Average net profit last 3 years',
    '#income_gross_salery_yearly': 'Gross annual salary',
    '#income_13th_month': '13th month',
    '#income_end_of_year': 'Year-end bonus',
    '#income_irregular': 'Irregularity allowance',
    '#income_overtime': 'Overtime',
    '#income_provision': 'Provision',

    '#retirement_agency': 'Retirement agency',
    '#retirement_aow_years': 'AOW years to build up',
    '#retirement_date': 'Expected retirement date',
    '#retirement_type': 'Type',
    '#retirement_monthly_premium': 'Gross retirement amount per month',
    '#retirement_saved': 'Build up retirement',
    '#retirement_goal': 'Retirement to be achieved',

    '#obligation_agency': 'Company',
    '#obligation_bank_account': 'Account number',
    '#obligation_principal': 'Principal amount',
    '#obligation_original_debt': 'Starting amount',
    '#obligation_monthly_amount': 'Monthly amount',
    '#obligation_yearly_amount': 'Annual amount',
    '#obligation_additional_redeemed': 'Additional repaid amount',
    '#obligation_current_balance': 'Current balance',
    '#obligation_current_balance_update': 'Balance per',


    //obligations
    '#original_debt': 'Original',
    '#rest_debt': 'Remaining',
    '#monthly_obligation': 'Monthly obligation',
    '#Betaalrekening_met_roodstandmogelijkheid': 'Current account with overdraft',
    '#Persoonlijke_lening': 'Personal loan',
    '#Doorlopend_krediet': 'Revolving credit',
    '#Studievoorschot': 'Study advance',
    '#Studieschuld': 'Student debt',
    '#Alimentatie_ex-partner': 'Alimony ex-partner',

    //avatar
    '#pick_image': 'pick an image',
    '#save': 'save',

    //message
    '#subject': 'Subject',
    '#message_to_advisor': 'A message to your advisor..',
    '#message_sent': 'The message has been sent.\nYour advisor will contact you as soon as possible.',
    '#message_error': 'The message was not sent correctly.\nYou can try again with the button below.',
    '#message_error_file_size': 'The message was not sent correctly.\nOne of the attached documents is too big. The maximum filesize of the document can be 2MB.\nYou can try again with the button below.',

    //damage modal
    '#damage_type_home': 'home',
    '#damage_type_vehicle': 'vehicle',
    '#damage_type_liability': 'liability',
    '#damage_type_travel': 'travel',
    '#damage_type_theft': 'theft',
    '#damage_type_other': 'other',
    '#type_description': 'What type of damage did you get?',
    '#date_description': 'When did the damage occur?',
    '#cost_description': 'What are the (estimated) costs?',
    '#damage_date': 'When did it happen?',
    '#reason_description': 'What happened?',

    '#document_claim_form': 'Claim form',
    '#document_purchase_invoice': 'Purchase invoice',
    '#document_quote_repair_costs': 'Repair quote',
    '#document_damage': 'Damaged goods',
    '#document_other': 'Other',
    '#document_drivers_license': 'Drivers license',
    '#document_other_party': 'Details counterparty',
    '#document_repairing_party': 'Details repair service',
    '#document_account_number_payment': 'Payment account number',

    '#title_add_images': 'Would you like to share documents? You can add them below.',

    '#address': 'Address',
    '#primary_living': 'current residential address',
    '#action_make_appointment': 'Plan appointment',
    '#action_make_appointment_online': 'plan your appointment online',
    '#action_get_contact': 'contact us',
    '#preferred_date': 'Which date do you prefer?',
    '#preferred_appointment_type': 'For what reason do you want to make an appointment?',
    '#appointment_category': 'select category',
    '#appointment_type': 'select appointment type',
    '#appointment_reason': 'What is the appointment about?',
    '#my_appointments': "appointments",
    '#cannot_create_appointment': 'Cannot plan an appointment. Contact your advisor for planning an appointment',
    '#pakket': 'Bundle',
    '#advisor_judge': 'under assessment by advisor',

    '#undertitle_contact_1': 'Do you have any questions or a comment? Let us know at ',
    '#undertitle_contact_2': ' or call ',
    '#undertitle_contact_3': '. If you want ',
    '#undertitle_contact_4': ' to read more information about our services, take a look at our ',
    '#route': 'Directions',
    '#save_password': 'save password',
    '#title_change_password': 'Here you can change your password',
    '#current_password': 'Current password',
    '#new_password': 'New password',
    '#new_password_confirmation': 'Confirm new password',
    '#new_password_equal': 'Both passwords must be the same',
    '#password_changed': 'Your password has been successfully changed.',
    '#password_saved': 'Your password has been successfully saved.',
    '#password_changeError': 'Something went wrong while changing your password.\nYou can try again with the button below',
    '#password_min_length': 'The password has to be at least 8 characters long',

    //nieuwe    
    '#income_now': 'Income',
    '#income_later': 'Retirement',
    '#built_up_retirement': 'Saved retirement',
    '#currently_open': 'we are open',
    '#currently_closed': 'we are closed',
    '#expires_on': 'Expires on',
    '#yes': 'Yes',
    '#no': 'No',
    '#close': 'close',
    '#cost': 'Cost',
    '#coverage': 'Coverage',
    '#data': 'Data',
    '#insured_person': 'Insured person:',
    '#policy_number': 'Policy number',
    '#advisor': 'advisor',
    '#clear_cookies_1': 'Does this keep happening? Try',
    '#clear_cookies_2': 'deleting your cookies',

    '#mortgage_number': 'Mortgage number',
    '#mortgage_parts': 'Mortgage parts',
    '#mortgage_inschrijving': 'Mortgage registration',
    '#mortgage_original_hoofdsom': 'Original mortgage principal',
    '#mortgage_rest_hoofdsom': 'Remaining mortgage principal',
    '#mortgage_verhogen_zonder_notaris': 'Space to increase mortgage without notary',
    '#mortgage_vorm': 'Repayment form',

    '#mortgage_interest_type': 'Interest type',
    '#mortgage_interest_percentage': 'Interest rate',
    '#mortgage_fixed_interest': 'fixed interest',
    '#part': 'Part',

    '#inventarisatie': 'Inventory',
    '#documenten_leveren': 'Upload documents',
    '#action_documenten_leveren': 'upload documents',
    '#rente_voorstel': 'Interest proposal',
    '#beoordeling_geldverstrekker': 'Review by lender',
    '#beoordeling_notaris': 'Review by notary',

    '#inventarisatie_short': 'start',
    '#documenten_leveren_short': 'Documents',
    '#rente_voorstel_short': 'Proposal',
    '#beoordeling_geldverstrekker_short': 'Lender',
    '#beoordeling_notaris_short': 'Notary',

    '#step': 'Step',
    '#of': 'of',


    '#title_living': 'My house',
    '#koopsom': 'Purchase sum',
    '#marktwaarde': 'Market value',
    '#wozwaarde': 'WOZ value',
    '#bouwjaar': 'Construction year',
    '#opp_woning': 'Surface area',
    '#opp_perceel': 'Plot area',
    '#kale_huur': 'Bare rent',
    '#service_kosten': 'Service costs',
    '#other_kosten': 'Other costs',

    '#documents': 'Documents',
    '#damage_amount': 'Damage amount',
    '#damage': 'damage',

    '#damage_soon': 'Do you want to share additional documents? That will be possible here soon. It is always possible to share documents in My documents',
    '#damage_claim': 'You recently reported a damage. Your advisor is handling it for you. View your damage report here.',

    '#total_retirement_title': 'Total retirement',
    '#total_retirement_goal': 'Total retirement to be achieved',
    '#total_retirement_accumulated': 'Total accrued retirement',
    '#build-up': 'ACCRUED',


    '#house_build': 'Build',
    '#house_size': 'Surfaces',
    '#house_energy': 'Energy',
    '#house_type': 'House type',
    '#house_buildyear': 'Build year',
    '#house_function': 'Function',
    '#house_function_status': 'Function status',
    '#house_rentable': 'Rentable',
    '#house_house': 'House',
    '#house_land': 'Plot',
    '#house_solar': 'Solar panels',
    '#house_smartmeter': 'Smart meter',
    '#house_energy_label': 'Energy label',
    '#house_energy_label_end_date': 'Energy label valid until',

    '#image': 'Image',
    '#document': 'Document',

    '#action_hide_keyboard': 'Hide keyboard',

    '#sell': 'sell plans',
    '#sustain': 'sustainability',
    '#appraise': 'appraise',

    '#title_living_sell': 'Plans for selling a home',
    '#title_living_sustain': 'Making a home more sustainable',
    '#title_living_appraise': 'Appraising a home',

    '#undertitle_living_sell': 'You have plans to sell your home. Your advisor will be happy to look at the possibilities with you.',
    '#undertitle_living_sustain': 'You have plans to make your home more sustainable. Your advisor will be happy to look at the possibilities with you.',
    '#undertitle_living_appraise': 'Would you like to have your home appraised? Your advisor will be happy to look at the possibilities with you.',

    '#undertitle_living_sell_u': 'You have plans to sell your home. Your advisor will be happy to look at the possibilities with you.',
    '#undertitle_living_sustain_u': 'You have plans to make your home more sustainable. Your advisor will be happy to look at the possibilities with you.',
    '#undertitle_living_appraise_u': 'Would you like to have your home appraised? Your advisor will be happy to look at the possibilities with you.',

    '#add_comment': 'Would you like to share more information? Please add a short description',
    '#add_comment_sell': 'Would you like to share some information about your plans? This way we can prepare ourselves for the appointment.',
    '#add_comment_sustain': 'Would you like to share some information about your plans? This way we can prepare ourselves for the appointment.',
    '#add_comment_appraise': 'Would you like to share some information? This way we can prepare ourselves for the appointment.',

    '#select_house_sell': 'Below you can select the house you want to sell',
    '#select_house_sustain': 'Below you can select the house you want to make more sustainable',
    '#select_house_appraise': 'Below you can select the house you want to appraise',
    '#selected_house_sell': 'The house you want to sell',
    '#selected_house_sustain': 'The house you want to make more sustainable',
    '#selected_house_appraise': 'The house you want to have appraised',

    '#action_request': 'request',

    '#damage_report': 'Damage report',
    '#shared_documents': 'Documents supplied',

    '#input_fields_required': 'Not all required fields are filled in.',
    '#input_fields_select_house': "Please make sure to select a house.",

    '#mortgage_requested': 'Mortgage pending',
    '#title_reset_password': 'Reset password',
    '#title_save_password': 'Set password',

    '#action_goto_login': 'to the login page',

    '#notifications_title': 'Allow notifications',
    '#notifications_text': "Don't want to miss any important messages? You can open the app settings here to turn on notifications.",
    '#cancel': 'Cancel',
    '#open_settings': 'Open settings',

    '#action_add_insurance': 'add insurance',
    '#title_add_insurance': 'Add an insurance policy',
    '#input_fields_document': 'Remember to add a document?',

    '#add_insurance_policy': 'Have you also taken out insurance policies with other parties? Upload your policy sheet below. This will then be shared with your advisor and the insurance will be shown later in this overview.',
    '#policies_tenant': 'via your advisor',
    '#policies_other': 'elsewhere',

    '#add_obligation_contract': 'Do you also have obligations that are not yet known to your advisor? Upload your contract below. This will then be shared with your advisor and the obligation will be shown later within this overview.',
    '#action_add_obligation': 'add obligation',
    '#title_add_obligation': 'Add an obligation contract',



    '#mortgage_verbouwen': 'renovate',
    '#mortgage_verhuizen': 'move',
    '#mortgage_hypotheekcheck': 'mortgagecheck',
    '#mortgage_enrich': 'edit mortgage',
    '#mortgage_create': 'create mortgage',


    '#title_mortgage_verhuizen': 'Reporting a move',
    '#title_mortgage_verbouwen': 'Report a renovation',
    '#title_mortgage_hypotheekcheck': 'Request a mortgagecheck',

    '#undertitle_mortgage_verhuizen': 'You have plans to buy another house. Do you have your eye on another house and would you like to investigate the possibilities for moving? Your adviser will be happy to discuss the options with you during a meeting.',
    '#undertitle_mortgage_verbouwen': 'You would like to renovate and are curious about the possibilities for applying for a building fund account, a loan or a change in your mortgage? You may be able to afford the renovation yourself. Your advisor will be happy to take a look with you.',
    '#undertitle_mortgage_hypotheekcheck': 'You already have a mortgage, but you want to know if it can be better or cheaper? Your adviser will be happy to look at your mortgage together to determine whether it still suits your needs.',

    '#add_comment_mortgage': 'Would you like to share some information? This way we can prepare ourselves well for the appointment.',

    '#select_house_verhuizen': 'Below you can select a house for which a move is planned',
    '#select_house_verbouwen': 'Below you can select a house that you want to renovate',
    '#select_house_hypotheekcheck': 'You can select a home below',

    '#selected_house_verhuizen': 'The house for which a move is planned',
    '#selected_house_verbouwen': 'The house you want to renovate',
    '#selected_house_hypotheekcheck': 'The selected house',

    '#founded_on': 'Founded on',

    '#footer_text': 'DossierData © ',
    '#footer_disclaimer1': 'No rights can be derived from this overview.',
    '#footer_disclaimer2': 'The administration of the insurer and the lender is always leading',

    '#no_results_bookable_times': 'There are no bookable times for the preferred date. Choose another date.',
    '#send_appointment_error': 'Something went wrong while making the appointment.',
    '#made_appointment': `Appointment has been created. The appointment can be found in 'My appointments'.`,

    '#appointment_with': 'Appointment with:',
    '#appointment_on': 'Appointment on:',
    '#appointment_description': 'Appointment description:',

    '#cancel_appointment_title': 'Cancel appointment',
    '#cancel_appointment_text': 'Are you sure you want to cancel the appointment?',

    '#after_successful_login': 'You have successfully logged in.\nLoading data...',

    '#loading_mortgage_data': 'Your mortgage data is currently being loaded.\nPlease wait...',
    '#loading_policy_data': 'Your insurance details are currently being loaded.\nPlease wait...',

    '#recently_shared_documents': 'Shared documents',

    '#living_address': 'Home address',

    '#install_update': 'There is a new version available, do you want to update the customerportal?',

    '#times_available': 'Time(s) available',

    '#select_account': 'Select an account',
    '#login_other_account': 'Login with another account',

    '#change_avatar': 'Change profile picture',
    '#change_info': 'Change details',
    '#change_password': 'Change password',
    '#status': 'Status',

    '#action_remove': 'remove',
    '#removing_document': 'You can remove documents up to an hour after uploading.',

    '#message_to_reply_to': 'The message you are replying to',

    '#action_read_more': 'read more',
}
