import { useWindowDimensions, View, ViewStyle } from "react-native"
import { useStyle } from "hooks";

interface props {
    children?: React.ReactNode
    style?: ViewStyle,
    childStyle?: ViewStyle,
    columns?: number
}

export default ({ children, style, childStyle, columns = 4 }: props) => {

    const styles = useStyle();
    const windowSize = useWindowDimensions();
    const largeScreen = windowSize.width >= 768;
    const gridItemStyle = largeScreen ? {
        flex: `1 1 calc((100% - 60px) / ${columns})`, 
        maxWidth: `calc((100% - 60px) / ${columns})`
    } : {
        flex: `1 1 100%`, 
        maxWidth: `100%`
    };
    
    return (
        <View style={[styles.flexRow, styles.flexWrap, styles.gap30, style]}>            
            {children && children.map((child: React.ReactNode, index: number) => (
                <View 
                    key={index} 
                    style={[
                        gridItemStyle,
                        childStyle
                    ]}
                >
                    {child}
                </View>
            ))}
        </View>
    );
}
