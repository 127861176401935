import TText from "../../components/TText";
import { BaseDrawerModal, DatePickerModal } from "components/Modals";
import { useStyle } from "hooks";
import { View } from "react-native-animatable";
import Button from "components/Button";
import { useState } from "react";
import moment from "moment";
import { DateTimeFormat, EnergyLabels } from "enums";
import { Icon, TextInput, Toggle } from "components";
import { colors } from "styles";
import { AdviesWidgetUtils, ValutaUtils } from "utils";
import TabView from "components/TabView";
import { Card } from "components/Card";

interface maximumMortgageType {
    monthly_payments: number,
    maximum_mortgage: number,
    interest_rate: number,
    total_gross_annual_income: number
}
interface scenarioResultType {
    maximum_mortgage: maximumMortgageType,
    maximum_mortgage_partner?: maximumMortgageType,
    scenario_description: string
}

export default ({ settings } : { settings: Record<string, any> }) => {

    const styles = useStyle();
    const rowStyle = [styles.flexRow, styles.gap10, styles.alignCenter];

    const [birthDate, setBirthDate] = useState(moment('02-12-1979'));
    const [annualIncome, setAnnualIncome] = useState(40000);
    const [pensionIncome, setPensionIncome] = useState(20000);
    const [oldAgeIncome, setOldAgeIncome] = useState(15000);
    const [survivorIncome, setSurvivorIncome] = useState(13000);

    const [hasStudentDebt, setHasStudentDept] = useState(false);
    const [studentDebtMonthlyPayment, setStudentDebtMonthyPayment] = useState(0);
    const [studentDebtInterestRate, setStudentDebtInterestRate] = useState(0);
    const [hasLoans, setHasLoans] = useState(false);
    const [loans, setLoans] = useState(0);
    const [hasLeaseLoan, setHasLeaseLoan] = useState(false);
    const [leaseHold, setLeaseHold] = useState(0);
    const [hasPrivateLease, setHasPrivateLease] = useState(false);
    const [privateLease, setPrivateLease] = useState(0);
    const [privateLeaseDuration, setPrivateLeaseDuration] = useState(0);
    const [hasPartnerAlimony, setHasPartnerAlimony] = useState(false);
    const [partnerAlimony, setPartnerAlimony] = useState(0);


    const [addPartner, setAddPartner] = useState(true);
    const [partnerBirthDate, setPartnerBirthDate] = useState(moment('07-29-1982'));
    const [partnerAnnualIncome, setPartnerAnnualIncome] = useState(40000);
    const [partnerPensionIncome, setPartnerPensionIncome] = useState(20000);
    const [partnerOldAgeIncome, setPartnerOldAgeIncome] = useState(15000);
    const [partnerSurvivorIncome, setPartnerSurvivorIncome] = useState(13000);

    const [partner_hasStudentDebt, partner_setHasStudentDept] = useState(false);
    const [partner_studentDebtMonthlyPayment, partner_setStudentDebtMonthyPayment] = useState(0);
    const [partner_studentDebtInterestRate, partner_setStudentDebtInterestRate] = useState(0);
    const [partner_hasLoans, partner_setHasLoans] = useState(false);
    const [partner_loans, partner_setLoans] = useState(0);
    const [partner_hasLeaseLoan, partner_setHasLeaseLoan] = useState(false);
    const [partner_leaseHold, partner_setLeaseHold] = useState(0);
    const [partner_hasPrivateLease, partner_setHasPrivateLease] = useState(false);
    const [partner_privateLease, partner_setPrivateLease] = useState(0);
    const [partner_privateLeaseDuration, partner_setPrivateLeaseDuration] = useState(0);
    const [partner_hasPartnerAlimony, partner_setHasPartnerAlimony] = useState(false);
    const [partner_partnerAlimony, partner_setPartnerAlimony] = useState(0);
    
    const [addEnergyLabel, setAddEnergyLabel] = useState(true);
    const [energyLabel, setEnergyLabel] = useState('a');

    const [showDatePicker, setShowDatePicker] = useState(false);

    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState<Record<string, scenarioResultType>>();

    const translateScenarioKey: Record<string, string> = {
        current_situation: 'Huidige situatie',
        break_up: 'Einde relatie',
        death_partner: 'Wanneer je partner overlijd',
        incapacitated: 'Wanneer je arbeidsongeschikt raakt',
        retirement: 'Wanneer je met pensioen gaat',
        unemployment: 'Wanneer je je baan verliest'
    }

    const doTest = async () => {

        setLoading(true);

        const data = {
            applicant: {
                date_of_birth: birthDate.format(DateTimeFormat.server_date),
                income: {
                    annual_employment_income: annualIncome,
                    state_pension_income: pensionIncome,
                    old_age_pension_income: oldAgeIncome,
                    survivor_pension_income: survivorIncome
                },
                obligations: {
                    has_student_debt: hasStudentDebt,
                        student_debt_monthly_payment: studentDebtMonthlyPayment,
                        student_debt_interest_rate: Math.max(0, Math.min(100, Number(studentDebtInterestRate))),
                    has_lease_loan: hasLeaseLoan,
                        lease_hold: leaseHold,
                    has_private_lease: hasPrivateLease,
                        private_lease: privateLease,
                        private_lease_duration: privateLeaseDuration,
                    has_loans: hasLoans,
                        loans: loans,
                    has_partner_alimony: hasPartnerAlimony,
                        partner_alimony: partnerAlimony
                }
            },        
            step: "scenarios",
        }

        if ( addPartner ){
            data.partner = {
                date_of_birth: partnerBirthDate.format(DateTimeFormat.server_date),
                income: {
                    annual_employment_income: partnerAnnualIncome,
                    state_pension_income: partnerPensionIncome,
                    old_age_pension_income: partnerOldAgeIncome,
                    survivor_pension_income: partnerSurvivorIncome
                },
                obligations: {
                    has_student_debt: partner_hasStudentDebt,
                        student_debt_monthly_payment: partner_studentDebtMonthlyPayment,
                        student_debt_interest_rate: Math.max(0, Math.min(100, Number(partner_studentDebtInterestRate))),
                    has_lease_loan: partner_hasLeaseLoan,
                        lease_hold: partner_leaseHold,
                    has_private_lease: partner_hasPrivateLease,
                        private_lease: partner_privateLease,
                        private_lease_duration: partner_privateLeaseDuration,
                    has_loans: partner_hasLoans,
                        loans: partner_loans,
                    has_partner_alimony: partner_hasPartnerAlimony,
                        partner_alimony: partner_partnerAlimony
                }
            };
        }

        if ( addEnergyLabel ){
            data.energy_label = energyLabel;
        }

        const response = await AdviesWidgetUtils.getScenarios(data);

        setResult(response.data.result);

        setLoading(false);

    }

    const renderForm = () => {
        return (
            <View style={styles.gap10}>
                <Card style={[{backgroundColor: colors.primary2}]}>

                    <TText md semibold>Hoofdklant</TText>
    
                    <View style={rowStyle}>
                        <TText style={{flex: 1}}>Geboorte datum:</TText>
                        <Button
                            label={birthDate.format(DateTimeFormat.display_date)}
                            onPress={() => setShowDatePicker('customer')}
                            style={{minWidth: 250}}
                        />
                    </View>
    
                    <View style={rowStyle}>
                        <TText style={{flex: 1}}>Bruto inkomen per jaar:</TText>
                        <TextInput 
                            value={annualIncome} 
                            onChangeText={(text) => setAnnualIncome(text)}
                            style={{minWidth: 250, backgroundColor: colors.white}}
                        />
                    </View>
    
                    <View style={rowStyle}>
                        <TText style={{flex: 1}}>Bruto werknemerspensioen per jaar:</TText>
                        <TextInput 
                            value={pensionIncome} 
                            onChangeText={(text) => setPensionIncome(text)}
                            style={{minWidth: 250, backgroundColor: colors.white}}
                        />
                    </View>
    
                    <View style={rowStyle}>
                        <TText style={{flex: 1}}>Bruto AOW:</TText>
                        <TextInput 
                            value={oldAgeIncome} 
                            onChangeText={(text) => setOldAgeIncome(text)}
                            style={{minWidth: 250, backgroundColor: colors.white}}
                        />
                    </View>

                    <View style={rowStyle}>
                        <TText style={{flex: 1}}>Bruto nabestaandenpensioen per jaar:</TText>
                        <TextInput 
                            value={survivorIncome} 
                            onChangeText={(text) => setSurvivorIncome(text)}
                            style={{minWidth: 250, backgroundColor: colors.white}}
                        />
                    </View>

                    <View style={styles.p10}/>

                    <View style={{marginBottom: 10}}>                    
                        <View style={[rowStyle, styles.gap10]}>
                            <Toggle initialValue={false} onChange={(checked)=>setHasStudentDept(checked)}/>
                            <TText sm semibold>Studielening verplichtingen</TText>
                        </View>
                        {hasStudentDebt &&
                            <View>
                                <View style={[rowStyle]}>
                                    <TText style={{flex: 1}}>Maandlast:</TText>
                                    <TextInput 
                                        value={studentDebtMonthlyPayment} 
                                        onChangeText={(text) => setStudentDebtMonthyPayment(text)}
                                        style={{minWidth: 250, backgroundColor: colors.white}}
                                    />
                                </View>    
                                <View style={[rowStyle]}>
                                    <TText style={{flex: 1}}>Rente percentage (0 - 100):</TText>
                                    <TextInput 
                                        value={studentDebtInterestRate} 
                                        onChangeText={(text) => setStudentDebtInterestRate(text)}
                                        style={{minWidth: 250, backgroundColor: colors.white}}
                                    />
                                </View>    
                            </View>
                        }
                    </View>

                    <View style={{marginBottom: 10}}>                   
                        <View style={[rowStyle, styles.gap10]}>
                            <Toggle initialValue={false} onChange={(checked)=>setHasLoans(checked)}/>
                            <TText sm semibold>Doorlopend krediet verplichtingen</TText>
                        </View>
                        {hasLoans &&
                        <View>
                                <View style={[rowStyle]}>
                                    <TText style={{flex: 1}}>Limiet van het krediet:</TText>
                                    <TextInput 
                                        value={loans} 
                                        onChangeText={(text) => setLoans(text)}
                                        style={{minWidth: 250, backgroundColor: colors.white}}
                                    />
                                </View> 
                            </View>
                        }
                    </View>

                    <View style={{marginBottom: 10}}>
                        <View style={[rowStyle, styles.gap10]}>
                            <Toggle initialValue={false} onChange={(checked)=>setHasLeaseLoan(checked)}/>
                            <TText sm semibold>Erfpacht verplichtingen</TText>
                        </View>
                        {hasLeaseLoan &&
                            <View>
                                <View style={[rowStyle]}>
                                    <TText style={{flex: 1}}>Maandlast:</TText>
                                    <TextInput 
                                        value={leaseHold} 
                                        onChangeText={(text) => setLeaseHold(text)}
                                        style={{minWidth: 250, backgroundColor: colors.white}}
                                    />
                                </View> 
                            </View>
                        }
                    </View>

                    <View style={{marginBottom: 10}}>
                        <View style={[rowStyle, styles.gap10]}>
                            <Toggle initialValue={false} onChange={(checked)=>setHasPrivateLease(checked)}/>
                            <TText sm semibold>Private lease verplichtingen</TText>
                        </View>
                        {hasPrivateLease &&
                            <View>
                                <View style={[rowStyle]}>
                                    <TText style={{flex: 1}}>Maandlast:</TText>
                                    <TextInput 
                                        value={privateLease} 
                                        onChangeText={(text) => setPrivateLease(text)}
                                        style={{minWidth: 250, backgroundColor: colors.white}}
                                    />
                                </View> 
                                <View style={[rowStyle]}>
                                    <TText style={{flex: 1}}>Aantal maanden dat de private lease loopt:</TText>
                                    <TextInput 
                                        value={privateLeaseDuration} 
                                        onChangeText={(text) => setPrivateLeaseDuration(text)}
                                        style={{minWidth: 250, backgroundColor: colors.white}}
                                    />
                                </View> 
                            </View>
                        }
                    </View>

                    <View style={{marginBottom: 10}}>
                        <View style={[rowStyle, styles.gap10]}>
                            <Toggle initialValue={false} onChange={(checked)=>setHasPartnerAlimony(checked)}/>
                            <TText sm semibold>Partneralimentatie verplichtingen</TText>
                        </View>
                        {hasPartnerAlimony &&
                            <View>
                                <View style={[rowStyle]}>
                                    <TText style={{flex: 1}}>Maandlast:</TText>
                                    <TextInput 
                                        value={partnerAlimony} 
                                        onChangeText={(text) => setPartnerAlimony(text)}
                                        style={{minWidth: 250, backgroundColor: colors.white}}
                                    />
                                </View> 
                            </View>
                        }
                    </View>
                    
                </Card>



    
                <Card style={[styles.pv20, {backgroundColor: colors.primary2}]}>
    
                    <View style={[rowStyle, styles.gap10]}>
                        <Toggle initialValue={true} onChange={(checked)=>setAddPartner(checked)}/>
                        <TText md semibold>Partner</TText>
                    </View>
            
                    {addPartner && 
                        <View>
                            <View style={rowStyle}>
                                <TText style={{flex: 1}}>Geboorte datum:</TText>
                                <Button
                                    label={partnerBirthDate.format(DateTimeFormat.display_date)}
                                    onPress={() => setShowDatePicker('partner')}
                                    style={{minWidth: 250}}
                                />
                            </View>
            
                            <View style={rowStyle}>
                                <TText style={{flex: 1}}>Bruto inkomen per jaar:</TText>
                                <TextInput 
                                    value={partnerAnnualIncome} 
                                    onChangeText={(text) => setPartnerAnnualIncome(text)}
                                    style={{minWidth: 250, backgroundColor: colors.white}}
                                />
                            </View>
            
                            <View style={rowStyle}>
                                <TText style={{flex: 1}}>Bruto werknemerspensioen per jaar:</TText>
                                <TextInput 
                                    value={partnerPensionIncome} 
                                    onChangeText={(text) => setPartnerPensionIncome(text)}
                                    style={{minWidth: 250, backgroundColor: colors.white}}
                                />
                            </View>
            
                            <View style={rowStyle}>
                                <TText style={{flex: 1}}>Bruto AOW:</TText>
                                <TextInput 
                                    value={partnerOldAgeIncome} 
                                    onChangeText={(text) => setPartnerOldAgeIncome(text)}
                                    style={{minWidth: 250, backgroundColor: colors.white}}
                                />
                            </View>

                            <View style={rowStyle}>
                                <TText style={{flex: 1}}>Bruto nabestaandenpensioen per jaar:</TText>
                                <TextInput 
                                    value={partnerSurvivorIncome} 
                                    onChangeText={(text) => setPartnerSurvivorIncome(text)}
                                    style={{minWidth: 250, backgroundColor: colors.white}}
                                />
                            </View>


                            <View style={styles.p10}/>

                            <View style={{marginBottom: 10}}>                    
                                <View style={[rowStyle, styles.gap10]}>
                                    <Toggle initialValue={false} onChange={(checked)=>partner_setHasStudentDept(checked)}/>
                                    <TText sm semibold>Studielening verplichtingen</TText>
                                </View>
                                {partner_hasStudentDebt &&
                                    <View>
                                        <View style={[rowStyle]}>
                                            <TText style={{flex: 1}}>Maandlast:</TText>
                                            <TextInput 
                                                value={partner_studentDebtMonthlyPayment} 
                                                onChangeText={(text) => partner_setStudentDebtMonthyPayment(text)}
                                                style={{minWidth: 250, backgroundColor: colors.white}}
                                            />
                                        </View>    
                                        <View style={[rowStyle]}>
                                            <TText style={{flex: 1}}>Rente percentage (0 - 100):</TText>
                                            <TextInput 
                                                value={partner_studentDebtInterestRate} 
                                                onChangeText={(text) => partner_setStudentDebtInterestRate(text)}
                                                style={{minWidth: 250, backgroundColor: colors.white}}
                                            />
                                        </View>    
                                    </View>
                                }
                            </View>

                            <View style={{marginBottom: 10}}>                   
                                <View style={[rowStyle, styles.gap10]}>
                                    <Toggle initialValue={false} onChange={(checked)=>partner_setHasLoans(checked)}/>
                                    <TText sm semibold>Doorlopend krediet verplichtingen</TText>
                                </View>
                                {partner_hasLoans &&
                                <View>
                                        <View style={[rowStyle]}>
                                            <TText style={{flex: 1}}>Limiet van het krediet:</TText>
                                            <TextInput 
                                                value={partner_loans} 
                                                onChangeText={(text) => partner_setLoans(text)}
                                                style={{minWidth: 250, backgroundColor: colors.white}}
                                            />
                                        </View> 
                                    </View>
                                }
                            </View>

                            <View style={{marginBottom: 10}}>
                                <View style={[rowStyle, styles.gap10]}>
                                    <Toggle initialValue={false} onChange={(checked)=>partner_setHasLeaseLoan(checked)}/>
                                    <TText sm semibold>Erfpacht verplichtingen</TText>
                                </View>
                                {partner_hasLeaseLoan &&
                                    <View>
                                        <View style={[rowStyle]}>
                                            <TText style={{flex: 1}}>Maandlast:</TText>
                                            <TextInput 
                                                value={partner_leaseHold} 
                                                onChangeText={(text) => partner_setLeaseHold(text)}
                                                style={{minWidth: 250, backgroundColor: colors.white}}
                                            />
                                        </View> 
                                    </View>
                                }
                            </View>

                            <View style={{marginBottom: 10}}>
                                <View style={[rowStyle, styles.gap10]}>
                                    <Toggle initialValue={false} onChange={(checked)=>partner_setHasPrivateLease(checked)}/>
                                    <TText sm semibold>Private lease verplichtingen</TText>
                                </View>
                                {partner_hasPrivateLease &&
                                    <View>
                                        <View style={[rowStyle]}>
                                            <TText style={{flex: 1}}>Maandlast:</TText>
                                            <TextInput 
                                                value={partner_privateLease} 
                                                onChangeText={(text) => partner_setPrivateLease(text)}
                                                style={{minWidth: 250, backgroundColor: colors.white}}
                                            />
                                        </View> 
                                        <View style={[rowStyle]}>
                                            <TText style={{flex: 1}}>Aantal maanden dat de private lease loopt:</TText>
                                            <TextInput 
                                                value={partner_privateLeaseDuration} 
                                                onChangeText={(text) => partner_setPrivateLeaseDuration(text)}
                                                style={{minWidth: 250, backgroundColor: colors.white}}
                                            />
                                        </View> 
                                    </View>
                                }
                            </View>

                            <View style={{marginBottom: 10}}>
                                <View style={[rowStyle, styles.gap10]}>
                                    <Toggle initialValue={false} onChange={(checked)=>partner_setHasPartnerAlimony(checked)}/>
                                    <TText sm semibold>Partneralimentatie verplichtingen</TText>
                                </View>
                                {partner_hasPartnerAlimony &&
                                    <View>
                                        <View style={[rowStyle]}>
                                            <TText style={{flex: 1}}>Maandlast:</TText>
                                            <TextInput 
                                                value={partner_partnerAlimony} 
                                                onChangeText={(text) => partner_setPartnerAlimony(text)}
                                                style={{minWidth: 250, backgroundColor: colors.white}}
                                            />
                                        </View> 
                                    </View>
                                }
                            </View>
                            
                        </View>
                    }
    
                </Card>

                <Card style={[styles.pv20, {backgroundColor: colors.primary2}]}>
    
                    <View style={[rowStyle, styles.gap10]}>
                        <Toggle initialValue={true} onChange={(checked)=>setAddEnergyLabel(checked)}/>
                        <TText md semibold>#house_energy_label</TText>
                    </View>
                    {addEnergyLabel &&
                        <View style={{ marginTop: 8, flexDirection: 'row', flexWrap: 'wrap', gap: 10 }}>
                            {
                                Object.keys(EnergyLabels).map((type, index) => {
                                    return (
                                        <Button
                                            key={'el' + index}
                                            label={EnergyLabels[type]}
                                            onPress={() => setEnergyLabel(type)}
                                            type={"secondary"}
                                            light={energyLabel !== type}
                                        />
                                    )
                                })
                            }
                        </View>
                    }

                </Card>
    
                <View style={[styles.pv20, styles.flexRow, styles.justifyEnd]}>
                    <Button 
                        loading={loading}
                        label="Doe de test"
                        onPress={doTest}
                    />
                </View>
            </View>
        );
    }

    const renderResults = () => {  
        return (
            <>
                <TabView tabs={['Hoofdklant', 'Partner']}>
                    {['maximum_mortgage', 'partner_maximum_mortgage'].map((resultPerPerson) => (
                        <View key={resultPerPerson} style={styles.gap30}>
                            {Object.keys(result).map((resultKey: string) => {
                                const resultScenario = result[resultKey][resultPerPerson];
                                return resultScenario ? (
                                    <View style={[styles.borderRadius20, styles.p20, {backgroundColor: colors.primary2}]}>
                                        <TText md semibold primary>{translateScenarioKey[resultKey]}</TText>
                                        <TText>{result[resultKey].scenario_description}</TText>
            
                                        <View style={[styles.borderRadius10, styles.p20, {backgroundColor: colors.primary, marginTop: 10}]}>
                                            <View style={rowStyle}>
                                                <TText white style={{minWidth: 200}}>Totaal inkomen:</TText>
                                                <TText white>{ValutaUtils.valutaString(resultScenario.total_gross_annual_income)}</TText>
                                            </View>
                                            <View style={rowStyle}>
                                                <TText white style={{minWidth: 200}}>Maximale hypotheek:</TText>
                                                <TText white>{ValutaUtils.valutaString(resultScenario.maximum_mortgage)}</TText>
                                            </View>
                                            <View style={rowStyle}>
                                                <TText white style={{minWidth: 200}}>Maandelijkse lasten:</TText>
                                                <TText white>{ValutaUtils.valutaString(resultScenario.monthly_payments)}</TText>
                                            </View>
                                            {/* <View style={rowStyle}>
                                                <TText white style={{minWidth: 165}}>Rente:</TText>
                                                <TText white>{resultScenario.interest_rate}</TText>
                                            </View> */}
                                        </View>
                                    </View>
                                ) : (
                                    <></>
                                );
                            })}
                        </View>
                    ))}      
                </TabView>                
                <View style={[styles.pv20, styles.flexRow, styles.justifyEnd]}>
                    <Button 
                        loading={loading}
                        icon={() => <Icon iconName="IconChevronLeft" iconColor={colors.white}/>}
                        label="Terug"
                        onPress={() => setResult(undefined)}
                    />
                </View>
            </>
        );
    }

    return (
       <BaseDrawerModal 
            drawerWidth={800} 
            visible={true}
            onClose={settings.hideModal}
            header={'Kan ik blijven wonen?'}
        >
            
            {result ? (
                renderResults()
            ) : 
                renderForm()
            }
            
            {showDatePicker &&
                <DatePickerModal
                    handleDateSelect={(e) => { showDatePicker === 'customer'? setBirthDate(e): setPartnerBirthDate(e) }}
                    hideModal={() => setShowDatePicker(false)}
                    initialDate={birthDate}
                    preventFutureDate
                />
            }
       </BaseDrawerModal>
    );
}
