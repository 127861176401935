import * as Animatable from 'react-native-animatable';
import { useStyle } from "hooks";
import { Dimensions, Modal, Platform, Pressable, ScrollView, View } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { colors } from "styles";
import { useContext, useRef } from "react";
import { ModalContext } from "providers/ModalProvider";
import CloseButton from "components/CloseButton";

export default ({ children, style = {}, hideModal: hide, hideCloseButton = false }) => {

    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const { open, hideModal } = useContext(ModalContext);
    const viewPressed = useRef(false);

    return (
        <Modal
            visible={open}
            transparent
        >
            <Pressable
                style={styles.fullscreen}
                onPress={() => {
                    if (viewPressed.current) return;
                    const close = hide || hideModal;
                    close();
                }}
            >
                <KeyboardAwareScrollView
                    showsVerticalScrollIndicator={false}
                    style={scopedStyles.background}
                    contentContainerStyle={{ alignItems: 'center' }}
                >

                    <Animatable.View
                        style={[scopedStyles.form, { minHeight: Dimensions.get('window').height * 0.7 }, style]}
                        animation={'zoomIn'}
                        duration={200}
                    >

                        <View style={scopedStyles.formDecoration} />

                        {!hideCloseButton && <CloseButton onPress={hide || hideModal} />}

                        <Pressable //this pressable is to prevent a click/tap on the form to close through the pressable further up
                            onTouchStart={() => {
                                viewPressed.current = true
                            }}
                            onTouchCancel={(e) => {
                                if (e.nativeEvent.touches.length == 0) viewPressed.current = false
                            }}
                            onTouchEnd={(e) => {
                                if (e.nativeEvent.touches.length == 0) viewPressed.current = false
                            }}
                            style={[{ flex: 1 }, Platform.OS === 'web' && { cursor: 'default' }]}
                        >
                            {children}
                        </Pressable>
                    </Animatable.View>
                </KeyboardAwareScrollView>
            </Pressable>
        </Modal>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        background: {
            backgroundColor: 'rgba(0,0,0,0.2)',
            width: '100%',
            flex: 1,
            marginTop: Platform.OS == 'ios' ? 20 : 0
        },
        form: {
            flex: 1,
            width: '70%',
            marginVertical: 150,
            backgroundColor: colors.background,
            borderRadius: 8,
            overflow: 'visible'
        },
        formDecoration: {
            position: 'absolute',
            height: 100,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: colors.formDecoration,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8
        },
        titel: {
            marginLeft: '1%',
            marginBottom: '2%'
        }
    },
    {
        [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
            form: {
                width: '65%'
            }
        },
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            form: {
                minWidth: 'auto',
                width: '90%',
                marginLeft: 0,
                marginVertical: '20%',
                minHeight: Dimensions.get('window').height * 0.8,
            }
        }
    }
)