import { Button, IconTitle } from "components";
import { TText } from "components";
import { DateTimeFormat } from 'enums';
import { useStyle } from "hooks";
import { Obligation } from "models";
import moment from "moment";
import { ModalContext } from "providers/ModalProvider";
import { useContext } from "react";
import { Dimensions, View } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import { ValutaUtils } from "utils";
import BaseModal from "./BaseModal";



export default ({ settings }) => {

    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();
    const { hideModal } = useContext(ModalContext);
    const largeScreen = Dimensions.get('window').width > 992;



    const obligation: Obligation = settings.obligation;
    const data = {
        customDescriptions: [
            { title: '#obligation_agency', value: obligation.agency },
            { title: '#obligation_bank_account', value: obligation.number },
            obligation.start_date && { title: '#start_date', value: moment(obligation.start_date).format(DateTimeFormat.display_date) },
            obligation.end_date && { title: '#end_date', value: moment(obligation.end_date).format(DateTimeFormat.display_date) }
        ].filter(item => item),
        customIncome: [
            obligation.principal_amount && { title: '#obligation_hoofdsom', value: ValutaUtils.valutaString(obligation.principal_amount) },
            obligation.original_debt && { title: '#obligation_original_debt', value: ValutaUtils.valutaString(obligation.original_debt) },
            obligation.monthly_amount && { title: '#obligation_monthly_amount', value: ValutaUtils.valutaString(obligation.monthly_amount) },
            obligation.gross_annual_obligation && { title: '#obligation_yearly_amount', value: ValutaUtils.valutaString(obligation.gross_annual_obligation) },
            obligation.additional_redeemed_amount && { title: '#obligation_additional_redeemed', value: ValutaUtils.valutaString(obligation.additional_redeemed_amount) },
            obligation.current_balance && { title: '#obligation_current_balance', value: ValutaUtils.valutaString(obligation.current_balance) },
            obligation.current_balance && { title: '#obligation_current_balance_update', value: moment(obligation.updated_at).format(DateTimeFormat.display_date) }
        ].filter(item => item)
    }



    const renderForm = () => {

        return (
            <View style={scopedStyles.container}>

                <View style={{ flex: 1, marginBottom: 30 }}>

                    <IconTitle
                        Icon={settings.icon}
                        title={settings.title}
                    >
                        <TText lg semibold primary>{ValutaUtils.valutaString(settings.total)}</TText>
                        <TText md primary> #p/m</TText>
                    </IconTitle>

                    {largeScreen ? renderLargeScreenParts() : renderSmallScreenParts()}

                </View>


                <View style={{ height: 100, justifyContent: 'center', alignItems: "flex-end" }}>

                    <View style={{ flexDirection: "row" }}>
                        <Button
                            label='#action_close'
                            type={"secondary"}
                            onPress={() => hideModal()}
                        />
                    </View>

                </View>

            </View >
        )
    }

    const renderLargeScreenParts = () => {

        return (
            <View style={scopedStyles.dataForm}>

                <View style={scopedStyles.dataFormParts}>

                    <TText sm black semibold style={[scopedStyles.nItem, { borderTopLeftRadius: 4, borderTopRightRadius: largeScreen ? 0 : 4 }]}>#description</TText>

                    <View style={[{ paddingLeft: 8, marginRight: 8 }]}>
                        {data.customDescriptions.map((desc, index) => {
                            return (
                                <View key={index} style={{ flexDirection: 'row', justifyContent: "space-between", paddingVertical: 4 }}>
                                    <TText sm black semibold>{desc.title}</TText>
                                    <TText sm grey semibold>{desc.value}</TText>
                                </View>
                            )
                        })}
                    </View>

                </View>

                <View style={scopedStyles.dataFormParts}>

                    <TText sm black semibold style={[scopedStyles.nItem, { borderTopRightRadius: 4, borderTopLeftRadius: largeScreen ? 0 : 4 }]}>#income</TText>

                    <View style={{ paddingHorizontal: 8 }}>
                        {data.customIncome.map((desc, index) => {
                            return (
                                <View key={'d' + index} style={{ flexDirection: 'row', justifyContent: "space-between", paddingVertical: 4 }}>
                                    <TText sm black semibold>{desc.title}</TText>
                                    <TText sm grey semibold>{desc.value}</TText>
                                </View>
                            )
                        })}
                    </View>

                </View>

            </View>
        );

    }


    const renderSmallScreenParts = () => {

        return (
            <View style={{ flex: 1, justifyContent: 'center' }}>

                <View>

                    <TText sm black semibold style={[scopedStyles.nItem, { borderTopLeftRadius: 4, borderTopRightRadius: 4 }]}>#description</TText>

                    <View style={[{ paddingLeft: 8, paddingRight: 8, backgroundColor: colors.white }]}>
                        {data.customDescriptions.map((desc, index) => {
                            return desc && (
                                <View key={index} style={{ paddingVertical: 4 }}>
                                    <TText sm black semibold>{desc.title}</TText>
                                    <TText sm grey semibold>{desc.value}</TText>
                                </View>
                            )
                        })}
                    </View>

                    <View style={{ height: 20 }}></View>

                    <TText sm black semibold style={[scopedStyles.nItem, { borderTopRightRadius: 4, borderTopLeftRadius: 4 }]}>#income</TText>

                    <View style={[{ paddingLeft: 8, paddingRight: 8, backgroundColor: colors.white }]}>
                        {data.customIncome.map((desc, index) => {
                            return desc && (
                                <View key={index} style={{ paddingVertical: 4 }}>
                                    <TText sm black style={{ flex: 1 }}>{desc.title}</TText>
                                    <TText sm grey semibold>{desc.value}</TText>
                                </View>
                            )
                        })}
                    </View>

                </View>

            </View>
        );

    }






    return (
        <BaseModal style={scopedStyles.modal}>

            {renderForm()}

        </BaseModal>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            width: '50%',
            height: 'auto',
            minHeight: 0,
            minWidth: 485,
            marginTop: 120,
            backgroundColor: colors.background,
            borderRadius: 8,
            overflow: 'visible'
        },
        container: {
            height: '100%',
            paddingTop: 20,
            paddingHorizontal: 30
        },
        titel: {
            marginVertical: 30
        },
        noteText: {
            backgroundColor: colors.lightgrey,
            flexGrow: 1,
            marginBottom: 30,
            padding: 8,
            borderRadius: 8,
            verticalAlign: "top",
            borderColor: colors.grey,
            borderWidth: 1
        },
        dataForm: {
            width: '100%',
            flexDirection: 'row',
            marginBottom: 20
        },
        dataFormParts: {
            flex: 1,
            backgroundColor: colors.white,
            borderRadius: 4
        },
        nItem: {
            backgroundColor: colors.formDecoration,
            paddingVertical: 12,
            paddingLeft: 8
        }
    },
    {
        [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
            form: {
                width: '65%'
            }
        },
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal: {
                minWidth: 'auto',
                width: '90%',
                marginLeft: 0,
                marginVertical: '20%',
            },
            titel: {
                marginBottom: 20,
                marginTop: 0
            },
            dataForm: {
                width: '100%',
                flexDirection: 'column'
            },
            dataFormParts: {
                marginBottom: 30
            }
        }
    }
)