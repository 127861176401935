import { useStyle } from "hooks";
import { CreateResponsiveStyle } from "rn-responsive-styles";
import { colors } from "styles";
import { Card } from "./Card";
import { ScrollView } from "react-native";

export default ({children} : {children?: any}) => {

    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();    

    return (
        <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{minHeight: '100%'}}>
            <Card style={[scopedStyles.page, styles.flexStretch, styles.gap30, styles.ph40]}>
                {children}
            </Card>
        </ScrollView>
    );
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        page: {
            padding: 0,
            paddingBottom: 40,
            backgroundColor: colors.background,
            borderRadius: 0,
            borderBottomLeftRadius: 35
        }
    }
);