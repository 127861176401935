import { Icons } from "icons";
import { Button } from "components";
import DatePickerModal from "components/Modals/DatePickerModal";
import { SelectModal } from "components/Modals/index";
import { TText } from "components";
import { DateTimeFormat, QueryKeys } from "enums";
import { useStyle, useTranslation } from "hooks";
import {
    OnlineAfsprakenAppointmentType,
    OnlineAfsprakenBookableTime,
    OnlineAfsprakenResource
} from "models";
import moment, { Moment } from "moment";
import { ModalContext } from "providers/ModalProvider";
import { StoreContext } from "providers/StoreProvider";
import React, { useContext, useEffect, useState } from "react";
import {
    ActivityIndicator,
    Dimensions, Platform,
    Pressable,
    TextInput,
    View,
    useWindowDimensions
} from "react-native";
import { useQuery } from "react-query";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { colors } from "styles";
import Colors from "styles/colors";
import { OnlineAfsprakenUtils, QueryUtils } from "utils";
import BaseModal from "./BaseModal";

interface AvailableResource {
    id: number;
    count: number;
}

export default () => {

    const { makeAppointment } = useContext(StoreContext);
    const scopedStyles = scopedResponsiveStyles();
    const { width } = useWindowDimensions();
    const styles = useStyle();
    const translate = useTranslation();
    const { hideModal } = useContext(ModalContext);
    const { data: appointments, refetch: refetchAppointments } = useQuery(QueryKeys.APPOINTMENTS, QueryUtils.fetchOnlineAfsprakenAppointments);
    const { data: customer } = useQuery(QueryKeys.CUSTOMER, QueryUtils.fetchCustomer);
    const { data: categories, isLoading } = useQuery(QueryKeys.APPOINTMENT_TYPES, QueryUtils.fetchOnlineAfsprakenAppointmentTypes);
    const { data: resources, isLoading: isResourcesLoading } = useQuery(QueryKeys.APPOINTMENT_TYPES_RESOURCES, QueryUtils.fetchOnlineAfsprakenResources);
    const [loading, setLoading] = useState({ appointments: false, times: false });
    const [bookableTimes, setBookableTimes] = useState(new Array<OnlineAfsprakenBookableTime>());
    const [availableResources, setAvailableResources] = useState(new Array<AvailableResource>());
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showTypePicker, setShowTypePicker] = useState(false);
    const [showCategoryPicker, setShowCategoryPicker] = useState(false);
    const [preferredDate, setPreferredDate] = useState<Moment | null>(null);
    const [appointmentTypeId, setAppointmentTypeId] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [resourceId, setResourceId] = useState(null);
    const [selectedBookableTime, setSelectedBookableTime] = useState(null);
    const [description, setDescription] = useState('');
    const [createAppointmentError, setCreateAppointmentError] = useState('');
    const [createAppointmentSuccess, setCreateAppointmentSuccess] = useState(false);

    useEffect(() => {
        if (preferredDate && appointmentTypeId) {
            fetchBookableTimes();
        }
    }, [preferredDate, appointmentTypeId]);

    const sendAppointment = () => {
        if (!selectedBookableTime) return;

        setLoading({ ...loading, appointments: true });

        const momentTimeStart = moment(`${selectedBookableTime.date} ${selectedBookableTime.startTime}`, 'YYYY-MM-DD hh:mm');
        const momentTimeEnd = moment(`${selectedBookableTime.date} ${selectedBookableTime.endTime}`, 'YYYY-MM-DD hh:mm');

        const timeStart = `${momentTimeStart.format("YYYY-MM-DD")}T${momentTimeStart.format("HH:mm:ss")}+01:00`;
        const timeEnd = `${momentTimeEnd.format("YYYY-MM-DD")}T${momentTimeEnd.format("HH:mm:ss")}+01:00`;

        OnlineAfsprakenUtils.storeOnlineAfsprakenAppointment({
            customer_id: customer.id,
            appointment_type_id: appointmentTypeId,
            time_start: timeStart,
            time_end: timeEnd,
            summary: '',
            description
        }).then((result) => {
            refetchAppointments();
            setCreateAppointmentSuccess(true);
            makeAppointment();
        }).catch(() => {
            setCreateAppointmentError('#send_appointment_error');
        }).finally(() => {
            setLoading({ ...loading, appointments: false });
        })
    }

    const fetchBookableTimes = () => {
        if (!preferredDate || !appointmentTypeId) return null;

        setLoading({ ...loading, times: true });

        const startDate = preferredDate.format(DateTimeFormat.server_date);
        const endDate = preferredDate.format(DateTimeFormat.server_date);

        OnlineAfsprakenUtils.fetchOnlineAfsprakenBookableTimes({
            start_date: startDate,
            end_date: endDate,
            appointment_type_id: appointmentTypeId,
        }).then((result: Array<OnlineAfsprakenBookableTime>) => {
            setBookableTimes(result);
            setAvailableResourcesArray(result);
        }).finally(() => {
            setLoading({ ...loading, appointments: false });
        });
    }

    const setAvailableResourcesArray = (data: Array<OnlineAfsprakenBookableTime>) => {
        const resources = new Array<AvailableResource>();
        data.map((bookableTime: OnlineAfsprakenBookableTime) => {
            if (!bookableTime.resourceId) return;
            let index = resources.findIndex((res) => res.id === bookableTime.resourceId);
            if (index >= 0) {
                resources[index].count++;
                return;
            }
            resources.push({
                id: bookableTime.resourceId,
                count: 1
            });
        });
        setAvailableResources(resources);
    }

    const renderDatePicker = () => {
        if (!showDatePicker) return null;
        return (
            <DatePickerModal
                handleDateSelect={(e: any) => {
                    setSelectedBookableTime(null);
                    setBookableTimes(new Array<OnlineAfsprakenBookableTime>());
                    setAvailableResources(new Array<AvailableResource>());
                    setPreferredDate(e);
                    setShowDatePicker(!showDatePicker);
                }}
                hideModal={() => setShowDatePicker(!showDatePicker)}
                initialDate={preferredDate ?? new Date()}
                preventPastDate
            />
        );
    }

    const renderCategoryPicker = () => {
        if (!showCategoryPicker) return null;
        return (
            <SelectModal
                data={categories.length >= 0 ? categories : []}
                handleSelectValue={(e: any) => {
                    setAppointmentTypeId(null);
                    setSelectedBookableTime(null);
                    setBookableTimes(new Array<OnlineAfsprakenBookableTime>());
                    setAvailableResources(new Array<AvailableResource>());
                    setPreferredDate(null);
                    setCategoryId(e);
                    setShowCategoryPicker(false);
                }}
                hideModal={() => setShowCategoryPicker(false)}
                initialValue={appointmentTypeId}
                buttonText={'#action_appointment_type'}
                title="#appointment_category"
            />
        );
    }

    const renderTypePicker = () => {
        const selectedCategory = categoryId ? categories.find((category) => category.id === categoryId) : null;
        if (!showTypePicker || !selectedCategory) return null;
        return (
            <SelectModal
                data={selectedCategory.onlineAfsprakenAppointmentTypes}
                handleSelectValue={(e: any) => {
                    setResourceId(null);
                    setSelectedBookableTime(null);
                    setBookableTimes(new Array<OnlineAfsprakenBookableTime>());
                    setAvailableResources(new Array<AvailableResource>());
                    setPreferredDate(null);
                    setAppointmentTypeId(e);
                    setShowTypePicker(!showTypePicker);
                }}
                hideModal={() => setShowTypePicker(false)}
                initialValue={appointmentTypeId}
                buttonText={'#action_appointment_type'}
                title="#appointment_type"
            />
        );
    }

    const renderBookableTime = (bookableTime: OnlineAfsprakenBookableTime) => {

        const isSelected = (
            selectedBookableTime?.date === bookableTime.date &&
            selectedBookableTime?.startTime === bookableTime.startTime
        );

        return (
            <Pressable
                key={`bookable_time_${bookableTime.date}_${bookableTime.startTime}`}
                style={[
                    scopedStyles.bookableTimeItem,
                    {
                        backgroundColor: !selectedBookableTime ? colors.secondary9 : isSelected ? Colors.primary : Colors.white,
                        borderColor: !selectedBookableTime ? colors.secondary9 : isSelected ? Colors.primary : Colors.primary,
                        borderWidth: 1
                    }
                ]}
                onPress={() => {
                    setSelectedBookableTime(isSelected ? null : bookableTime);
                }}
            >
                <TText
                    sm
                    semibold
                    style={[
                        scopedStyles.bookableTimeItemText,
                        { color: !selectedBookableTime ? Colors.white : !isSelected ? Colors.primary : Colors.white }
                    ]}
                >
                    {moment(bookableTime.date, DateTimeFormat.server_date).format(DateTimeFormat.display_date)}
                </TText>
                <TText
                    sm
                    semibold
                    white
                    style={[
                        scopedStyles.bookableTimeItemText,
                        { color: !selectedBookableTime ? Colors.white : !isSelected ? Colors.primary : Colors.white }
                    ]}
                >
                    {bookableTime.startTime} - {bookableTime.endTime}
                </TText>
            </Pressable>
        );
    }

    const renderSelectButtons = () => {
        if (!isLoading && categories.length <= 0) {
            return (
                <View style={scopedStyles.buttonContainer}>
                    <TText sm error>#cannot_create_appointment</TText>
                </View>
            )
        }
        const selectedCategory = categoryId ? categories.find((category) => category.id === categoryId) : null;
        const selectedType = selectedCategory ? selectedCategory.onlineAfsprakenAppointmentTypes.find((type: OnlineAfsprakenAppointmentType) => type.id === appointmentTypeId) : null;
        return (
            <View style={scopedStyles.buttonContainer}>

                <TText sm primary style={{ marginBottom: 8 }}>#appointment_reason</TText>

                <View style={scopedStyles.buttonFlex}>
                    <Button
                        label={selectedCategory ? selectedCategory.name : '#appointment_category'}
                        onPress={() => setShowCategoryPicker(!showCategoryPicker)}
                        type={"secondary"}
                        light={selectedCategory}
                        style={scopedStyles.buttonSpacing}
                    />
                    {selectedCategory ?
                        <Button
                            label={selectedType ? selectedType.name : '#appointment_type'}
                            onPress={() => setShowTypePicker(!showTypePicker)}
                            type={"secondary"}
                            light={selectedCategory}
                            style={scopedStyles.buttonSpacing}
                        />
                        : null}
                </View>

                {selectedType ?
                    <>
                        <TText sm primary style={{ marginBottom: 8 }}>#preferred_date</TText>
                        <View style={scopedStyles.buttonFlex}>
                            <Button
                                label={preferredDate ? preferredDate.format(DateTimeFormat.display_date) : '#action_date'}
                                onPress={() => setShowDatePicker(!showDatePicker)}
                                type={"secondary"}
                                light={preferredDate}
                                style={scopedStyles.buttonSpacing}
                            />
                        </View>
                    </>
                    : null}
            </View>
        );
    }

    const renderSelectResource = () => {
        const selectedResource = resourceId ? resources.find((resource: OnlineAfsprakenResource) => resource.id === resourceId) : null;
        if (!appointmentTypeId || !preferredDate) return null;
        return (
            <View>
                <TText sm primary style={{ marginBottom: 8, textTransform: 'capitalize' }}>#action_appointment_type_resource</TText>
                {availableResources.map((availableResource: AvailableResource) => {
                    const resource = resources.find((res: OnlineAfsprakenResource) => res.id === availableResource.id)
                    return (
                        <View
                            key={`available_resource_${resource.id}`}
                            style={scopedStyles.resourceContainer}
                        >
                            <Button
                                label={resource.name}
                                onPress={() => setResourceId(resource.id)}
                                type={selectedResource?.id === resource.id ? "primary" : "secondary"}
                                light={!selectedResource}
                            />
                            <TText sm regular error style={scopedStyles.resourceTimesCount}>
                                {availableResource.count} #times_available
                            </TText>
                        </View>
                    );
                })}
            </View>
        )
    }

    const renderSidePanel = () => {
        return (
            <View style={[scopedStyles.advisorContainer, { backgroundColor: colors.primary2 }]}>
                <View
                    width={width > 768 ? 120 : 80}
                    height={width > 768 ? 120 : 80}
                    style={{
                        width: width > 768 ? 120 : 80,
                        height: width > 768 ? 120 : 80,
                        borderRadius: width > 768 ? 60 : 40,
                        backgroundColor: Colors.white,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {/* <Icons.Calendar size={50} color={colors.primary} /> */}
                </View>
            </View>
        );
    }

    const renderSuccessMessage = () => {
        const selectedCategory = categoryId ? categories.find((category) => category.id === categoryId) : null;
        const selectedType = selectedCategory ? selectedCategory.onlineAfsprakenAppointmentTypes.find((type: OnlineAfsprakenAppointmentType) => type.id === appointmentTypeId) : null;
        const selectedResource = resourceId ? resources.find((resource: OnlineAfsprakenResource) => resource.id === resourceId) : null;

        return (
            <View style={scopedStyles.container}>
                <View style={{ flex: 1, justifyContent: "center" }}>

                    <TText lg regular primary style={scopedStyles.title}>{'#made_appointment'}</TText>

                    <View style={scopedStyles.successMessage}>
                        <TText md primary style={{ marginBottom: 20 }}>{selectedCategory?.name} - {selectedType?.name}</TText>
                        <View style={styles.successMessageLine}>
                            <TText sm primary style={{ width: 220 }}>#appointment_with</TText>
                            <TText sm regular primary style={{ marginBottom: 20 }}>{selectedResource?.name}</TText>
                        </View>
                        <View style={styles.successMessageLine}>
                            <TText sm primary style={{ width: 220 }}>#appointment_on</TText>
                            <TText sm regular primary style={{ marginBottom: 20 }}>{moment(selectedBookableTime.date, DateTimeFormat.server_date).format(DateTimeFormat.display_date) + ","} van {selectedBookableTime.startTime} tot {selectedBookableTime.endTime + '.'}</TText>
                        </View>
                        <View style={styles.successMessageLine}>
                            <TText sm primary style={{ width: 220 }}>#appointment_description</TText>
                            <TText sm regular primary style={{ marginBottom: 20 }}>{description}</TText>
                        </View>
                    </View>
                </View>
                <View style={{ minHeight: 100, flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ flexDirection: "row" }}>
                        <Button
                            label={'#action_close'}
                            type={"secondary"}
                            onPress={hideModal}
                        />
                    </View>
                </View>
            </View>
        );
    }

    return (
        <BaseModal style={scopedStyles.modal}>
            <View style={[[
                {
                    flex: 1,
                    flexDirection: width < 768 ? 'column-reverse' : 'row',
                    justifyContent: "space-between"
                },
                Platform.OS === 'web' && { cursor: 'default' }
            ]]}>
                {createAppointmentSuccess ?
                    renderSuccessMessage()
                    :
                    <View style={scopedStyles.container}>
                        <View style={{ flex: 1 }}>
                            <TText lg regular primary style={scopedStyles.title}>#action_make_appointment</TText>
                            <TText md primary style={{ marginBottom: 24 }}>#undertitle_create_appointment</TText>
                            <View style={scopedStyles.inputContainer}>
                                {isLoading ? (
                                    <View style={{ flexDirection: 'row' }}><ActivityIndicator color={colors.primary} size={40} /></View>
                                ) : (
                                    <>
                                        {renderSelectButtons()}
                                        {renderSelectResource()}
                                    </>
                                )}
                            </View>

                            <View style={scopedStyles.bookableTimeContainer}>
                                {(preferredDate && appointmentTypeId && !loading.times && bookableTimes.length <= 0) ?
                                    <TText sm error>#no_results_bookable_times</TText>
                                    : null}
                                {loading.times ?
                                    <View><ActivityIndicator color={colors.primary} size={40} /></View>
                                    : null}
                                {bookableTimes.filter((boTi) => boTi.resourceId === resourceId).map((bookableTime: OnlineAfsprakenBookableTime) =>
                                    renderBookableTime(bookableTime)
                                )}
                            </View>

                            {(appointmentTypeId && selectedBookableTime) ?
                                <>
                                    <TText sm primary style={{ marginBottom: 8 }}> #preferred_appointment_type ( #optional )</TText>
                                    <TextInput
                                        placeholder={translate('#type_message_placeholder')}
                                        multiline={true}
                                        numberOfLines={5}
                                        value={description}
                                        onChangeText={(value) => setDescription(value)}
                                        autoCorrect={false}
                                        style={[
                                            styles.textRegular,
                                            styles.textSM,
                                            scopedStyles.description
                                        ]}
                                    />
                                </>
                                : null}
                        </View>
                        <View style={{ height: 100, flexDirection: 'row', alignItems: 'center' }}>
                            <View style={{ flexDirection: "row" }}>
                                <Button
                                    label={'#action_make_appointment'}
                                    onPress={() => sendAppointment()}
                                    type={"secondary"}
                                    loading={loading.appointments}
                                    disabled={!appointmentTypeId || !selectedBookableTime}
                                />
                            </View>
                            {createAppointmentError != '' &&
                                <View style={{ marginLeft: 12, flex: 1 }}>
                                    <TText sm error>{createAppointmentError}</TText>
                                </View>
                            }
                        </View>
                    </View>
                }
                {renderSidePanel()}
            </View>
            {renderDatePicker()}
            {renderCategoryPicker()}
            {renderTypePicker()}
        </BaseModal>
    )

}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        modal: {
            minWidth: 485,
            width: '75%',
            height: '75%',
            minHeight: 485,
            marginTop: 150
        },
        container: {
            flex: 1,
            flexDirection: 'column',
            paddingHorizontal: 30,
        },
        title: {
            marginVertical: 20
        },
        inputContainer: {
            flexDirection: 'column',
            marginBottom: 20,
        },
        buttonContainer: {
            marginTop: 8,
            marginRight: 8
        },
        buttonFlex: {
            flex: 1,
            flexDirection: "row",
            flexWrap: 'wrap',
        },
        buttonSpacing: {
            marginRight: 8,
            marginBottom: 8,
        },
        bookableTimeContainer: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginBottom: 20,
            marginTop: -8
        },
        bookableTimeItem: {
            padding: 10,
            marginRight: 10,
            marginBottom: 10,
            borderRadius: 8,
            width: 150,
        },
        bookableTimeItemText: {
            fontWeight: 'bold',
            color: Colors.white,
        },
        appointmentsContainer: {
            flexDirection: 'column',
            marginBottom: 20,
        },
        appointmentItem: {
            width: '100%',
            padding: 10,
            marginRight: 10,
            marginBottom: 10,
            borderRadius: 8,
            backgroundColor: Colors.primary
        },
        appointmentText: {
            fontWeight: 'bold',
            color: Colors.white,
        },
        appointmentDelete: {
            fontWeight: 'bold',
            color: Colors.error,
        },
        description: {
            backgroundColor: colors.lightgrey,
            flexGrow: 1,
            marginBottom: 30,
            padding: 8,
            borderRadius: 8,
            verticalAlign: "top",
            borderColor: colors.grey,
            borderWidth: 1
        },
        advisorContainer: {
            height: '100%',
            width: 200,
            borderBottomRightRadius: 8,
            alignItems: "center",
            justifyContent: 'center'
        },
        advisorContact: {
            justifyContent: "center",
            marginTop: 15
        },
        successMessage: {
            backgroundColor: colors.blackTransparent,
            borderRadius: 12,
            padding: 30,
            marginBottom: 30
        },
        successMessageLine: {
            width: '100%',
            flexDirection: "row",
            flexWrap: 'wrap'
        },
        resourceContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 8,
        },
        resourceName: {
            backgroundColor: colors.blackTransparent,
            borderRadius: 12,
            padding: 10,
        },
        resourceTimesCount: {
            marginLeft: 8
        }
    },
    {
        [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
            modal: {
                minWidth: 'auto',
                width: '90%',
                marginLeft: 0,
                marginVertical: '20%',
                minHeight: Dimensions.get('window').height * 0.80,
            },
            advisorContainer: {
                width: '100%',
                height: 120,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: 8,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            },
            advisorContact: {
                marginLeft: 12
            },
            bookableTimeItem: {
                width: '46.5%',
            },
        }
    }
)
