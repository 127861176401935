import { useStyle } from "hooks"
import { Dimensions, View } from "react-native"
import { colors } from "styles"
import { TText } from "components";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";
import { ScrollView } from "react-native-gesture-handler";
import { useState } from "react";



export default ({ title, children, specificTopHeight }: { title: string, children: any[], specificTopHeight?: number | string }) => {

    const styles = useStyle();
    const scopedStyles = scopedResponsiveStyles();

    //different margins for mobile web due to address bar
    const topHeight = Dimensions.get("window").width < 768 ? specificTopHeight || Dimensions.get("window").height * 0.7 : 390;

    //scrollprotection for the bottom (nested) scrollview.
    //prevents scrolling when not focused on that view
    const [touches, setTouches] = useState(0);
    const handleTouch = (e) => setTouches(e.nativeEvent.touches.length);

    const renderLargeScreen = () => {
        return (
            <ScrollView
                style={styles.fullscreen}
                contentContainerStyle={{ flexGrow: 1 }}
                stickyHeaderIndices={[1]}
            >
                <View style={[scopedStyles.topContainer, { height: topHeight }]}>
                    <TText xl regular primary>{title}</TText>
                    {children[0]}
                </View>

                {children[1]}

                <View style={[
                    {
                        width: '100%',
                        flex: 1,
                        backgroundColor: colors.darkestgrey,
                    }
                ]}>
                    {children[2]}
                </View>
            </ScrollView>
        );
    }

    const renderSmallScreen = () => {

        return (
            <ScrollView
                scrollEnabled={touches < 2}
                style={scopedStyles.scrollView}
                contentContainerStyle={{ flexGrow: 1 }}
                showsVerticalScrollIndicator={false}
                onTouchStart={handleTouch}
                onTouchEnd={handleTouch}
                stickyHeaderIndices={[1]}
            >
                <View style={{
                    height: topHeight,
                    paddingVertical: '2%',
                    paddingHorizontal: '5%',
                }}>
                    <TText xl regular primary>{title}</TText>
                    {children[0]}
                </View>

                {children[1]}

                {children[2]}

            </ScrollView>
        );
    }

    return (
        <View style={styles.fullscreen}>

            {Dimensions.get('window').width > 768 ? (
                renderLargeScreen()
            ) : (
                renderSmallScreen()
            )}
        </View>
    )
}

const scopedResponsiveStyles = CreateResponsiveStyle(
    {
        topContainer: {
            backgroundColor: colors.background
        },
        gradientContainer: {
            flex: 1
        },
        scrollView: {
            position: 'absolute',
            margin: 0,
            padding: 0,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0
        },
        scrollProtector: {
            position: "absolute",
            left: 0,
            top: 0,
            width: '100%',
            height: '120%',
            backgroundColor: '#0000'
        }
    }, {

}
)