import { useStyle } from "hooks";
import TText from "../../components/TText";
import { BaseDrawerModal } from "components/Modals";
import { StoreContext } from "providers/StoreProvider";
import { useContext } from "react";
import { View } from "react-native";
import { Button } from "components";

export default ({ visible, onClose } : { visible: boolean; onClose: () => void }) => {

    const styles = useStyle();
    const { logout, reloadApp } = useContext(StoreContext);
    
    return (
       <BaseDrawerModal 
            drawerWidth={500} 
            visible={visible}
            onClose={onClose}
            header={'Customer Drawer'}
        >
            <View style={[styles.flexStretch, styles.justifyEnd]}>
                <Button
                    label="#action_logout"
                    type="secondary"
                    onPress={()=>setTimeout(() => logout(), 150)}
                />
            </View>
       </BaseDrawerModal>
    );
}
