
import { createContext, useContext, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { colors } from "styles";
import * as scale from 'd3-scale'
import { Tenant } from "models"
import { QueryKeys } from "enums";
import { QueryUtils } from "utils";
import { StoreContext } from "./StoreProvider";

export const customColors = colors;
export const ThemeContext = createContext({});

export default ({ children }) => {

    //load colors
    const { triggerLoading } = useContext(StoreContext);
    const { data: tenant } = useQuery(QueryKeys.TENANT, QueryUtils.fetchTenant);    
    const colorsSet = useRef(false);
    useEffect(() => {
        if (!colorsSet.current && tenant instanceof Tenant){
            colorsSet.current = true;
            transformcustomColors(tenant);
            triggerLoading();
        }
    }, [tenant])

    const transformcustomColors = (tenant: Tenant) => {

        const primary = scale.scaleLinear()
            .domain([0, 100])
            .range([tenant.primary_color, colors.white]);

        const secondary = scale.scaleLinear()
            .domain([0, 100])
            .range([tenant.secondary_color, colors.white]);

        
        customColors.primary =  tenant.primary_color;
        customColors.primary9 = primary(10);
        customColors.primary8 = primary(20);
        customColors.primary7 = primary(30);
        customColors.primary6 = primary(40);
        customColors.primary5 = primary(50);
        customColors.primary4 = primary(60);
        customColors.primary3 = primary(70);
        customColors.primary2 = primary(80);
        customColors.primary1 = primary(90);

        customColors.secondary =  tenant.secondary_color;
        customColors.secondary9 = secondary(10);
        customColors.secondary8 = secondary(20);
        customColors.secondary7 = secondary(30);
        customColors.secondary6 = secondary(40);
        customColors.secondary5 = secondary(50);
        customColors.secondary4 = secondary(60);
        customColors.secondary3 = secondary(70);
        customColors.secondary2 = secondary(80);
        customColors.secondary1 = secondary(90);

        customColors.gradientFrom = customColors.primary;
        customColors.gradientTo = customColors.primary5; 
                
    }

    return (
        <ThemeContext.Provider value={{}}>
            {children}
        </ThemeContext.Provider>
    )
}