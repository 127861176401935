import { useFormik } from 'formik';
import { Platform, StyleSheet, View } from 'react-native'
import { Button, TextInput, TText } from 'components';
import { useStyle, useTranslation } from 'hooks';
import { object, string } from 'yup';
import { colors } from 'styles';

interface FormProps {
    loading: boolean
    data: {username: string, password: string, tenant: string},
    handleLogin: (v: any) => void
}

const LoginForm = ({ loading, data, handleLogin }: FormProps) => {

    const styles = useStyle();
    const translate = useTranslation();

    const loginSchema = object({
        otp: string().required('#login_error_authcode')
    });

    const {
        handleChange,
        handleSubmit,
        handleBlur,
        setValues,
        values,
        errors,
        touched
    } = useFormik({
        validationSchema: loginSchema,
        initialValues: { code: '', otp: '', tenant: data.tenant, password: data.password, username: data.username },
        onSubmit: values => handleLogin(values),
    });

    return (
        <View>

            <View style={{backgroundColor: colors.secondary9, borderRadius: 4, padding: 8, marginBottom: 8}}>
                <TText regular sm white>#login_authcode_sent</TText>
            </View>

            <TextInput
                tabIndex={2}
                label='#login_2FA'
                style={[styles.text, scopedStyles.input]}
                placeholder={translate('#login_2FA')}
                value={values.otp}
                onChangeText={handleChange('otp')}
                onBlur={handleBlur('otp')}
                onKeyPress={(e) => (e.keyCode === 13) && handleSubmit()}
                autoCapitalize='none'
            />
            <TText regular sm error style={scopedStyles.error}>{(touched.otp && errors.otp) ? errors.otp : ' '}</TText>

            <Button label='#login_login' onPress={handleSubmit} style={scopedStyles.button} loading={loading} />

        </View>
    );
};

const scopedStyles = StyleSheet.create({
    input: {
        width: '100%',
        marginVertical: 8
    },
    button: {
        marginTop: 24
    },
    error: {
        textAlign: 'right',
        marginBottom: -8
    }
});


export default LoginForm;
